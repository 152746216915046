import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { WalletProvider, SuietWallet, SuiWallet } from '@suiet/wallet-kit';
import '@suiet/wallet-kit/style.css';
import './styles/suiet-wallet-kit-custom.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <WalletProvider defaultWallets={[SuietWallet, SuiWallet]}>
            <App />
        </WalletProvider>
    </React.StrictMode>
);
