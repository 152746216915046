import React from 'react';
import styled from 'styled-components';
import { Social } from '../../styles/gateOmegaStyle';
import { FaMedium, FaTwitter, FaTelegramPlane } from 'react-icons/fa';
import { TfiWorld } from 'react-icons/tfi';

const Reserved = styled.div`
    font-size: 8px;
`;

const GateOmegaInfo = () => {
    return (
        <>
            <div>
                {' '}
                <hr
                    style={{ border: '0.1px solid grey', marginRight: '80px' }}
                />{' '}
            </div>
            <Social>
                <a
                    href="https://gateomega.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <TfiWorld />
                </a>
                <a
                    href="https://twitter.com/gateomega"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaTwitter />
                </a>
                <a
                    href="https://t.me/gateomega"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaTelegramPlane />
                </a>
                <a
                    href="https://gateomega.medium.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaMedium />
                </a>
            </Social>
            <Reserved>© 2021 GateOmega. All rights reserved.</Reserved>
        </>
    );
};

export default GateOmegaInfo;
