import styled from 'styled-components';
import { Border } from './border';
import { MdSearch } from 'react-icons/md';

export const ModalSearchIcon = styled(MdSearch)`
    font-size: 24px;
    color: ${(p) => p.theme.searchBorderColor};
    display: flex;
    align-items: center;
    margin-right: 8px;
`;

export const SearchStil = styled.form`
    display: flex;
    align-items: center;
    border: 1px solid ${(p) => p.theme.searchBorderColor};
    border-radius: 8px;
    padding: 4px;
    justify-content: space-between;
    background-color: ${(p) => p.theme.searchBackground};
    input {
        padding: 8px 12px;
        flex: 1;
        font-size: 16px;
        color: ${(p) => p.theme.inputColor};
        border: none;
        outline: none;
        background-color: transparent;
        &::placeholder {
            color: ${(p) => p.theme.placeholderColor};
        }
    }
`;

export const SearchButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
`;
export const Chart = styled(Border)`
    flex: 1 1 0%;
    gap: 4px;
    padding: 8px 8px;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: 400px;
    width: 100%;
    height: 100%;
`;

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    @media (min-width: 80rem) {
        flex-direction: row;
    }
`;
export const DelInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 0%;

    @media (min-width: 80rem) {
        max-width: 400px;
    }
`;

export const ChartWrapper = styled.div`
    display: flex;
    flex: 1 1 0%;
    /* gap: 16px; */
    /* padding: 18px 25px; */
    justify-content: center;
    background-color: ${(p) => p.theme.widgetBackgroundColor};
    /* flex: 1; */
    /* min-width: 0; // Flex item'ın container'ın boyutunu aşmasını önler */
`;
