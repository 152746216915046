const theme = {
    id: 'dark',
    primaryColor: 'black',
    secondaryColor: 'midnightblue',
    bodyBackgroundColor: '#20242d',
    bodyFontColor: '#daddde',
    selectColor: '#2e3952',
    toggleColor: 'white',
    widgetBackgroundColor: 'rgba(0, 0, 0, 0.1)',
    widgetBorderColor: 'rgb(64, 68, 79)',
    buttonColor: '#70ED9D',
    searchBackground: '#22554a',
    searchBorderColor: '#688881',
    placeholderColor: '#688881',
    inputColor: '#daddde',
    tableColor: '#70ED9D',
    accordionTitle: '#daddde94',
    accordionOpenTitle: '#54c9a8',
    accordionTitleBackground: 'rgb(32, 36, 45)',
    openAccordionTitleBackground: 'rgba(0, 0, 0, 0.1)',
    accordionContent: '#e1e4e6',
    accordionContentBackground: 'rgb(32, 36, 45, 0.2)',
    tabTitleColor: '#1B2430',
    tabItemColor: 'rgba(0, 0, 0, 0.1)',
    progressColor: '#22554a',
    alertToggleBackground: 'black',
};

export default theme;
