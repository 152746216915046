import axios from 'axios';

export const loadValidatorStatus = async () => {
    try {
        const responseStatus = await axios.get(
            'https://suigo.app/api/v5/uptime'
        );
        const data = responseStatus.data;
        window.__VALIDATOR_UPTIME_LOADER_CACHE__ = data;
        // console.log(data);
        return data;
    } catch (error) {
        console.error('Error fetching network stats:', error);
    }
};

export const loadValidatorStatusData = async (validatorName) => {
    try {
        const responseStatusData = await axios.get(
            `https://suigo.app/api/v5/uptimedata?name=${validatorName}`
        );
        // console.log(responseStatusData.data);
        return responseStatusData.data;
    } catch (error) {
        console.error('Error fetching network stats:', error);
    }
};
