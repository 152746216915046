import styled from 'styled-components';
import { Border } from './border';

export const Chart = styled(Border)`
    flex: 1 1 0%;
    gap: 4px;
    padding: 16px 8px;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: 400px;
    width: 100%;
    height: 100%;
`;

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    @media (min-width: 80rem) {
        flex-direction: row;
    }
`;
export const ValInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 0%;

    @media (min-width: 80rem) {
        max-width: 400px;
    }
`;

export const ChartWrapper = styled.div`
    flex: 1;
    min-width: 0; // Flex item'ın container'ın boyutunu aşmasını önler
`;
