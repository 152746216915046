import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import styled from 'styled-components';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { loadValidatorStatus } from '../../scriptsApi/statusApi';
import { TbArrowsSort } from 'react-icons/tb';
import { RiSortAsc, RiSortDesc } from 'react-icons/ri';
import {
    Wrapper,
    LoadingBar,
    CircleGreen,
    CircleRed,
    StyledSpan,
} from '../../styles/validatorTableStyle';
import { GlobalFilter } from '../search/GlobalFilter';
import LaunchIcon from '@mui/icons-material/Launch';

const StyledLink = styled(Link)`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    text-align: start;
    margin: -6px -6px -6px -6px;
    padding: 6px;
    /* border-radius: 6px; */
    text-decoration: none;
    font-weight: ${(p) => (p.isActive ? '600' : 'normal')};
    color: ${(p) => p.theme.tableColor};
    /* background-color: ${(p) => p.theme.widgetBackgroundColor}; */

    &:hover {
        background-color: ${(p) => p.theme.selectColor};
    }
`;

//preload data
const fetchTableData = async () => {
    try {
        let data;
        if (window.__TABLE_DATA_LOADER_CACHE__) {
            data = window.__TABLE_DATA_LOADER_CACHE__;
        } else {
            const response = await fetch(
                'https://suigo.app/api/v1/validators/'
            );
            data = await response.json();
        }
        if (Array.isArray(data)) {
            return { data: data, meta: { totalRowCount: data.length } };
        } else {
            return data;
        }
    } catch (error) {
        console.error('Error fetching table data:', error);
    }
};

export const Table = () => {
    const [uptime, setUptime] = useState([]);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const { data: fetchedData, isLoading } = useQuery(
        'table-data',
        fetchTableData
    );

    const fetchValidatorUptime = async () => {
        try {
            const data = await loadValidatorStatus();

            setUptime(data);
            // console.log(data);
            window.__VALIDATOR_UPTIME_LOADER_CACHE__ = data;
        } catch (error) {
            console.error('Error fetching uptime stats:', error);
        }
    };

    // const sortedData = useMemo(() => {
    //     return [...data].sort((a, b) => b.go_rating - a.go_rating);
    // }, [data]);

    const sortedData = useMemo(() => {
        return [...data]
            .sort((a, b) => b.apy - a.apy)
            .map((row, index) => ({ ...row, index }));
    }, [data]);

    useEffect(() => {
        // Uptime data initially fetch
        fetchValidatorUptime();

        // Then set an interval to fetch it every 10 minutes
        const interval = setInterval(() => {
            fetchValidatorUptime();
        }, 10 * 60 * 1000); // 10 minutes

        // Clear the interval when the component is unmounted
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (fetchedData?.data && uptime) {
            const dataWithStatus = fetchedData.data.map((row) => {
                const uptimeRow = uptime.find(
                    (uptimeItem) => uptimeItem.name === row.name
                );
                return {
                    ...row,
                    status: uptimeRow ? uptimeRow.status : 'N/A',
                };
            });
            setData(dataWithStatus);
        }
    }, [fetchedData, uptime]);

    const columns = React.useMemo(
        () => [
            {
                id: 'row',
                Header: '#',
                width: 40,
                Cell: (row) => {
                    return (
                        <div style={{ fontSize: '12px' }}>
                            {/* {row.row.index + 1} */}
                        </div>
                    );
                },

                canSort: false,
            },

            {
                id: 'validator',
                Header: 'Validators',
                accessor: 'name',
                width: 30,
                Cell: ({ cell, row }) => (
                    <StyledLink
                        to={`/${cell.value}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            window.scrollTo(0, 0);
                        }}
                    >
                        <div
                            style={{
                                width: '25px',
                                height: '25px',
                                borderRadius: '50%',
                                backgroundColor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'black',
                                overflow: 'hidden',
                                marginLeft: '4px',
                                // marginRight: '10px',
                            }}
                        >
                            {row.original.img_url ? (
                                <img
                                    src={row.original.img_url}
                                    alt=""
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    onError={(e) => {
                                        e.target.replaceWith(
                                            document.createTextNode(
                                                row.original.name.slice(0, 2)
                                            )
                                        );
                                    }}
                                />
                            ) : (
                                row.original.name.slice(0, 2)
                            )}
                        </div>
                        {cell.value}
                        {/* <LaunchIcon sx={{ fontSize: 12, color: 'grey' }} />{' '} */}
                    </StyledLink>
                ),
            },

            {
                Header: 'APY',
                accessor: 'apy',
                // width: 100,
                Cell: ({ value }) => `${value} %`,
            },
            {
                Header: 'Commission',
                accessor: 'commission',
                // width: 100,
                Cell: ({ value }) => `${value / 100} %`,
            },

            {
                Header: 'Total Stake',
                accessor: 'total_stake',
                // width: 100,

                Cell: ({ value }) =>
                    `${new Intl.NumberFormat().format(
                        Math.floor(value / 1000000000)
                    )} SUI`,
            },
            {
                Header: 'Voting Power',
                accessor: 'voting_power',
                // width: 100,
                Cell: ({ value }) => `${value / 100} %`,
            },
            {
                Header: 'Gas',
                accessor: 'gas_price',
                // width: 100,
                Cell: ({ value }) => `${value} MIST`,
            },
            {
                Header: 'Status',
                accessor: 'status',
                // width: 100,
                Cell: ({ value }) => (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                            justifyContent: 'flex-end',
                        }}
                    >
                        {value === 'active' ? <CircleGreen /> : <CircleRed />}
                        <span>
                            {value.charAt(0).toUpperCase()}
                            {value.slice(1)}
                        </span>
                    </div>
                ),
            },
        ],
        [sortedData]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data: sortedData,
            initialState: { sortBy: [{ id: 'row', asc: true }] },
        },
        useGlobalFilter,
        useSortBy
    );
    const { globalFilter } = state;
    // useTable({ columns, data: sortedData }, useGlobalFilter, useSortBy);
    return (
        <>
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <Wrapper>
                <span style={{ paddingTop: '2px' }}></span>
                {isLoading && <LoadingBar />}
                {
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}
                                            style={{ width: column.width }}
                                        >
                                            {column.id === 'validator' ? (
                                                <StyledSpan alignLeft>
                                                    {column.render('Header')}
                                                    {column.id !== 'row' && (
                                                        <span>
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <RiSortDesc />
                                                                ) : (
                                                                    <RiSortAsc />
                                                                )
                                                            ) : (
                                                                <TbArrowsSort />
                                                            )}
                                                        </span>
                                                    )}
                                                </StyledSpan>
                                            ) : (
                                                <StyledSpan alignRight>
                                                    {column.render('Header')}
                                                    {column.id !== 'row' && (
                                                        <span>
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <RiSortDesc />
                                                                ) : (
                                                                    <RiSortAsc />
                                                                )
                                                            ) : (
                                                                <TbArrowsSort />
                                                            )}
                                                        </span>
                                                    )}
                                                </StyledSpan>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        onClick={() => {
                                            navigate(`/${row.original.name}`);
                                            window.scrollTo(0, 0);
                                        }}
                                    >
                                        {row.cells.map((cell, cellIndex) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    onClick={(e) =>
                                                        e.stopPropagation()
                                                    }
                                                >
                                                    {cellIndex === 0
                                                        ? i + 1
                                                        : cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                }
            </Wrapper>
        </>
    );
};

const queryClient = new QueryClient();

const TableWithReactQueryProvider = () => (
    <QueryClientProvider client={queryClient}>
        <Table />
    </QueryClientProvider>
);

export default TableWithReactQueryProvider;
