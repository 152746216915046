import styled, { ThemeContext } from 'styled-components';
import { Border } from './border';

export const NetworkLeft = styled(Border)`
    flex: 1 1 0%;
    gap: 4px;
    padding: 18px 25px;
    -webkit-box-pack: center;
    justify-content: center;

    h1 {
        min-width: 0px;
        font-weight: 600;
        font-size: 1rem;
        color: #54c9a8;
    }

    p {
        margin: 4px 0px -6px;
        font-weight: 400;
        font-size: 1rem;
    }
`;

export const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 180px;
`;

export const PercentageText = styled.span`
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    align-items: center;
`;

export const ProgressBar = styled.progress`
    width: 100%;
    height: 10px;
    margin-top: 4px;
    /* accent-color: #54c9a8; */

    &[value] {
        --color: #54c9a8; /* the progress color */
        --background: #d8d7d7; /* the background color */

        border: none; /* Firefox add a default border */
        /* width: 200px; */
        /* margin: 0 10px; */
        border-radius: 10em;
        background: var(--background);
    }
    &[value]::-moz-progress-bar {
        border-radius: 10em;
        background: var(--color);
    }
    &[value]::-webkit-progress-bar {
        border-radius: 10em;
        background: var(--background);
    }
    &[value]::-webkit-progress-value {
        border-radius: 10em;
        background: var(--color);
    }
`;
