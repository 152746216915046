import { PageLayout } from '../components/layout/PageLayout';
import React from 'react';
import AllValidatorStats from '../components/validators/AllValidatorsStats';
import TableWithReactQueryProvider from '../components/tables/ValidatorTable';
import MyChart from '../components/charts/TotalStakeChart';
import Breadcrumbs from '../components/Breadcrumbs';
import {
    ChartWrapper,
    Chart,
    ValInfoWrapper,
    MainWrapper,
} from '../styles/validatorsStyle';
import { Helmet } from 'react-helmet-async';

export default function Validators() {
    return (
        <PageLayout>
            <Helmet>
                {' '}
                <title>SuiGo - Validators</title>
                <meta
                    property="og:url"
                    content="https://suigo.app/validators"
                />
            </Helmet>
            {/* <Breadcrumbs /> */}
            <MainWrapper>
                <ValInfoWrapper>
                    <AllValidatorStats />
                </ValInfoWrapper>
                <Chart>
                    <ChartWrapper>
                        <MyChart />
                    </ChartWrapper>
                </Chart>
            </MainWrapper>
            <TableWithReactQueryProvider />
        </PageLayout>
    );
}
