import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: calc(100vw - 32px);
    color: ${(p) => p.theme.bodyFontColor};
    background-color: ${(p) => p.theme.widgetBackgroundColor};
    border: 1px solid ${(p) => p.theme.widgetBorderColor};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 10px;
    border-radius: 12px;
    overflow-x: auto;
    font-size: 0.875rem;

    @media screen and (min-width: 64rem) {
        /* max-width: calc(100vw - 276px); */
    }

    table {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
    }

    thead {
        position: sticky;
        top: 0px;
        margin: 0px;
        th {
            z-index: 1;
        }

        th:first-of-type {
            border-radius: 12px 0px 0px;
        }

        th:last-of-type {
            border-radius: 0px 12px 0px 0px;
        }
    }

    tr {
        border-bottom: 1px solid ${(p) => p.theme.widgetBorderColor};

        > :first-child {
            position: sticky;
            left: 0px;
            z-index: 1;
        }
    }

    th,
    td {
        padding: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: ${(p) => p.theme.bodyBackgroundColor};
    }

    span {
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        align-items: center;
        flex-wrap: nowrap;
        gap: 4px;
        font-weight: 500;
        position: relative;
    }
`;
