import React, { useState, useEffect } from 'react';
import {
    AreaChart,
    Area,
    Tooltip,
    ResponsiveContainer,
    YAxis,
    XAxis,
} from 'recharts';

const SingleApyChart = ({ validatorData }) => {
    const [apyData, setApyData] = useState([]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const formattedDate = formatTooltipLabel(label);
            const [value, name] = formatTooltipValue(
                payload[0].value,
                payload[0].name
            );
            return (
                <div
                    style={{
                        position: 'relative',
                        bottom: '60px',
                        backgroundColor: 'transparent',
                        padding: '8px',
                    }}
                >
                    <p style={{ marginBottom: '1px', fontSize: '12px' }}>
                        {formattedDate}
                    </p>
                    <p style={{ fontSize: '14px' }}>{`${name}: ${value}`}</p>
                </div>
            );
        }
        return null;
    };
    const formatTooltipLabel = (value) => {
        const date = new Date(value);
        return date.toLocaleDateString(undefined, {
            day: '2-digit',
            month: 'short',
        });
    };

    const formatTooltipValue = (value, name) => {
        const formattedName = name === 'apy' ? 'APY' : name;
        return [`${value}%`, formattedName];
    };

    useEffect(() => {
        if (validatorData) {
            let transformedData = validatorData
                .slice(-30) // Son 30 veriyi al
                .map((item) => {
                    return {
                        date_time: new Date(item.date_time)
                            .toISOString()
                            .split('T')[0], // x value as date without time
                        apy: Number(item.apy).toFixed(2),
                    };
                });
            setApyData(transformedData);
        }
    }, [validatorData]);

    if (!apyData.length) {
        return <div>Loading...</div>;
    }

    return (
        <div style={{ width: 180, height: 50 }}>
            <ResponsiveContainer>
                <AreaChart
                    data={apyData}
                    margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 5,
                    }}
                >
                    <defs>
                        <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="5%"
                                stopColor="#54c9a8"
                                stopOpacity={1}
                            />
                            <stop
                                offset="25%"
                                stopColor="#54c9a8"
                                stopOpacity={0.3}
                            />
                            <stop
                                offset="95%"
                                stopColor="#54c9a8"
                                stopOpacity={0}
                            />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="date_time" hide={true} />
                    <YAxis domain={['auto', 'auto']} hide={true} />
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Tooltip
                        labelFormatter={formatTooltipLabel}
                        formatter={formatTooltipValue}
                        wrapperStyle={{ outline: 'none' }}
                        contentStyle={{
                            backgroundColor: 'transparent',
                            border: 'none',
                        }}
                    /> */}
                    <Area
                        type="monotone"
                        dataKey="apy"
                        stroke="#54c9a8"
                        fill="url(#colorUv)"
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SingleApyChart;
