import React from 'react';
import { Sidebar } from './Sidebar';
import { Header } from './Header';
import { Navbar } from './Navbar';
import {
    Content,
    MainWrapper,
    Wrapper,
    WrapperMain,
} from '../../styles/pageLayoutStyle';

export function PageLayout({ children }) {
    return (
        <>
            <Header />
            <Sidebar />
            <MainWrapper>
                <Content>
                    <Navbar />
                    {/* <WrapperMain> */}
                    {/* wrapper main deneme olarak eklendi */}
                    <Wrapper>{children}</Wrapper>
                    {/* </WrapperMain> */}
                </Content>
            </MainWrapper>
        </>
    );
}
