import styled from 'styled-components';

export const HeaderWrapper = styled.header`
    height: 64px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    padding: 12px 16px 0 16px;
    position: static;
    top: 0;
    margin-bottom: 0;
    justify-content: space-between;
    align-items: center;

    /* border-bottom: 1px solid ${(p) => p.theme.secondaryColor}; */
    @media (min-width: 64rem) {
        display: none;
        /* background-color: white; */
    }
`;

export const Menu = styled.nav`
    display: ${(p) => (p.open ? 'flex' : 'none')};
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100vh;
    /* height: 100%; */
    top: 0;
    left: 0;
    padding: 8px;
    /* border-bottom: 1px solid ${(p) => p.theme.buttonColor}; */
    background: ${(p) => p.theme.bodyBackgroundColor};
    z-index: 999;
    user-select: none;
    justify-content: flex-start;
    align-items: center;
`;

// export const MobileMenuIcon = styled.div`
//     margin: 0 0 0 auto;
//     width: 3rem;
//     min-width: 25px;
//     padding: 0 4px;

//     > div {
//         height: 3px;
//         background: ${(p) => p.theme.bodyFontColor};
//         margin: 5px 0;
//         width: 100%;
//     }

//     @media (min-width: 64rem) {
//         display: none;
//     }
// `;
// export const MobilCircle = styled.div`
//     /* padding: 0 10px; */
//     margin: 0 0 0 auto;
//     height: 3rem;
//     width: 3rem;
//     /* background-color: #bbb; */
//     border: 0.8px solid ${(p) => p.theme.toggleColor};
//     border-radius: 50%;
//     display: inline-block;
//     min-width: 25px;
//     padding: 0 4px;
//     position: relative;
//     /* position: fixed; */
// `;

export const MobileMenuIcon = styled.div`
    z-index: 1000;
    margin: 0 0 0 auto;
    min-width: 25px;
    width: 2.5rem;
    padding: 0px 5px;
    /* margin-left: -5px; */
    /* position: absolute; */
    /* right: 2.5px; */
    /* top: 4px; */
    > div {
        height: 2px;
        background: ${(p) => p.theme.bodyFontColor};
        margin: 5px 0;
        width: 100%;
        transition: all 0.2s ease;
        /* position: absolute; */
    }

    /* orta çizgiyi gizleyin */
    ${({ open }) =>
        open &&
        `
        > div:nth-child(2) {
            opacity: 0;
        }
    `}

    /* üst ve alt çizgileri çarpı şeklini alacak şekilde döndürün */
    ${({ open }) =>
        open &&
        `
        > div:nth-child(1) {
           
            width: 1.5rem;
            transform: rotate(45deg) translate(5px, 5px);
        }

        > div:nth-child(3) {
      
            width: 1.5rem;
            transform: rotate(-45deg) translate(5px, -5px);
        }
    `}

    @media (min-width: 64rem) {
        display: none;
    }
`;
