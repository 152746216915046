import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useTheme } from 'styled-components';

const DelegatorPieRatio = ({ stakesData, style }) => {
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({});
    const theme = useTheme();
    useEffect(() => {
        // console.log('stakesData prop updated:', stakesData);
        let totalPrincipal = 0;
        let totalEstimatedReward = 0;
        stakesData.forEach((validator) => {
            totalPrincipal += validator.stakes.reduce(
                (sum, stake) =>
                    sum + Number((stake.principal / 1000000000).toFixed(2)),
                0
            );
            totalEstimatedReward += validator.stakes.reduce((sum, stake) => {
                let reward = stake.estimatedReward
                    ? Number((stake.estimatedReward / 1000000000).toFixed(2))
                    : 0;
                return sum + reward;
            }, 0);
        });
        // console.log(totalEstimatedReward);
        const seriesData = [
            {
                name: 'Total Initial Stake',
                value: totalPrincipal,
            },
            {
                name: 'Total Estimated Reward',
                value: totalEstimatedReward,
            },
        ];

        // console.log(seriesData);
        setData(seriesData);
    }, [stakesData]);

    useEffect(() => {
        setOptions({
            title: {
                text: 'Total Stake / Rewards',

                bottom: 'left',
                textStyle: {
                    color: theme.bodyFontColor, // Set title text color
                },
            },

            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)',
                textStyle: {
                    // color: theme.bodyFontColor, // Set title text color
                },
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                textStyle: {
                    color: theme.bodyFontColor, // Set legend text color
                },
            },
            series: [
                {
                    name: 'Validator',
                    type: 'pie',
                    radius: '50%',
                    data: data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                    label: {
                        color: theme.bodyFontColor, // etiketlerin rengi
                        borderColor: 'transparent', // etiketin çevresindeki border rengi
                        // formatter: function (params) {
                        //     const maxLen = 10;
                        //     const str = params.name;
                        //     if (str.length <= maxLen) return str;

                        //     let formattedStr = '';
                        //     for (let i = 0; i < str.length; i++) {
                        //         formattedStr += str[i];
                        //         if (i > 0 && i % maxLen === 0) {
                        //             formattedStr += '\n';
                        //         }
                        //     }
                        //     return formattedStr;
                        // },
                    },
                },
            ],
        });
    }, [data, theme]);

    return (
        <ReactEcharts
            option={options}
            style={{ height: '100%', width: '100%' }}
        />
    );
};

export default DelegatorPieRatio;
