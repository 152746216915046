// import
import {
    loadNetworkStats,
    loadTpsStats,
    loadSuiPriceStats,
} from './networkStatsApi.js';
import { loadTableData } from './validatorTableApi.js';
import { loadEpochStats } from './epochApi.js';
import {
    loadLastStakesApy,
    loadTotalStakeData,
    loadAvgApyData,
} from './totalStakeApi.js';
import { loadValidatorStatus } from './statusApi.js';

// Global degisken
window.__DATA_LOADER_CACHE__ = loadNetworkStats();
window.__TABLE_DATA_LOADER_CACHE__ = loadTableData();
window.__EPOCH_DATA_LOADER_CACHE__ = loadEpochStats();
window.__SUI_DATA_LOADER_CACHE__ = loadSuiPriceStats();
window.__TPS_DATA_LOADER_CACHE__ = loadTpsStats();
window.__LAST_STAPY_DATA_LOADER_CACHE__ = loadLastStakesApy();
window.__TOTAL_STAKE_DATA_LOADER_CACHE__ = loadTotalStakeData();
window.__VALIDATOR_UPTIME_LOADER_CACHE__ = loadValidatorStatus();
window.__TOTAL_AVG_APY_DATA_LOADER_CACHE__ = loadAvgApyData();
