import { PageLayout } from '../components/layout/PageLayout';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { MainStats } from '../components/dashboard/MainStats';
import TableWithReactQueryProvider from '../components/tables/ValidatorTable';
import NetworkStatsLeft from '../components/dashboard/NetworkStatsLeft';
import Breadcrumbs from '../components/Breadcrumbs';
import { Helmet } from 'react-helmet-async';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    @media (min-width: 80rem) {
        flex-direction: row;
    }
`;

const BestWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 0%;

    @media (min-width: 80rem) {
        max-width: 400px;
    }
`;

export default function Dashboard() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        try {
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    }
    return (
        <PageLayout>
            {/* <Breadcrumbs /> */}
            <Helmet>
                <title>SuiGo - Dashboard</title>
                <meta property="og:url" content="https://suigo.app/dashboard" />
            </Helmet>
            <Wrapper>
                <BestWrapper>
                    <NetworkStatsLeft />
                </BestWrapper>
                <MainStats />
            </Wrapper>

            <TableWithReactQueryProvider />
        </PageLayout>
    );
}
