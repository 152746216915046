import React from 'react';
import styled from 'styled-components';

import { MdClose, MdSearch } from 'react-icons/md';
import { SettingsInputAntennaTwoTone } from '@mui/icons-material';

const TelegramButton = styled.button`
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: 0.2px solid #141414;
    width: 40%;
    border-radius: 8px;
    align-self: center;
    cursor: pointer;
    &:hover,
    :active {
        background: #22554a;
        color: #dadada;
        border: 0.2px solid #141414;
    }
`;

const ModalContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    /* align-content: flex-start; */
    align-items: left;
    line-height: 1.5;
    color: #e2e2e2;
    gap: 16px;
    margin-right: 16px;

    p {
        /* border-radius: 8px;
        border: 1px solid #688881; */
        margin: 0;
        padding: 0 8px;
        font-size: 12px;
    }
`;

const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    padding: 0;
    z-index: 10;
`;

const TelegramModal = ({
    setShowTelegramModal,
    setCommission,
    setUptime,
    uuid,
}) => {
    const handleSubmit = () => {
        // İşlem yapılıyor...
        setShowTelegramModal(false);
    };

    // Dinamik URL oluşturuluyor
    const telegramUrl = `https://t.me/suigobot?start=${uuid}`;

    return (
        <>
            <ModalContent>
                <p>
                    By clicking on the link and initializing the bot in
                    Telegram, you are giving consent to receive alerts based on
                    your chosen settings.
                </p>

                <CloseModalButton
                    aria-label="Close modal"
                    onClick={() => {
                        setShowTelegramModal(false);
                        setCommission(false);
                        setUptime(false);
                    }}
                />

                <a href={telegramUrl} target="_blank" rel="noopener noreferrer">
                    <TelegramButton onClick={handleSubmit}>
                        Open Telegram Bot
                    </TelegramButton>
                </a>
            </ModalContent>
        </>
    );
};

export default TelegramModal;

// const TelegramModal = ({ setShowTelegramModal, setCommission, setUptime }) => {
//     const handleSubmit = () => {
//         setShowTelegramModal(false);
//     };
//     return (
//         <>
//             <ModalContent>
//                 <p>
//                     {/* To activate your alerts, please follow these steps:
//                     <ul>
//                         <li>Click "submit" button to open our Telegram bot</li>
//                         <li>
//                             In Telegram, press the "Start" button to initialize
//                             the bot.
//                         </li>
//                         <li>
//                             Once the bot is initialized, your alerts will be
//                             activated.
//                         </li>
//                     </ul> */}
//                     By clicking on the link and initializing the bot in
//                     Telegram, you are giving consent to receive alerts based on
//                     your chosen settings.
//                 </p>

//                 <CloseModalButton
//                     aria-label="Close modal"
//                     onClick={() => {
//                         setShowTelegramModal(false);
//                         setCommission(false);
//                         setUptime(false);
//                     }}
//                 />

//                 <a
//                     style={{
//                         style: 'none',
//                         display: 'block',
//                     }}
//                     href="https://t.me/suigobot"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                 >
//                     <TelegramButton onClick={handleSubmit}>
//                         Open Telegram Bot
//                     </TelegramButton>
//                 </a>
//             </ModalContent>
//         </>
//     );
// };
