import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
const InfoWrapper = styled.div`
    padding: 18px 25px;
    margin-bottom: -10px;
    position: relative;
    background-color: ${(p) => p.theme.widgetBackgroundColor};
    display: flex;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.widgetBorderColor};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 10px;
    align-items: center;
    font-size: 1rem;

    /* @media (max-width: 32rem) {
        flex-direction: column;
        align-items: flex-start;
    } */

    @media (max-width: 64rem) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const FirstWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const StyledImg = styled.img`
    width: 2rem;
`;
const InfoLogo = styled.div``;

const ValidatorInfo = ({ validatorName, validatorData }) => {
    const [validator, setValidator] = useState([]);
    const [logoError, setLogoError] = useState(false);
    // function imageHandle() {}
    useEffect(() => {
        if (validatorData) {
            // console.log(validatorData);
            const validatorLogo =
                validatorData[validatorData.length - 1]?.img_url;
            const validatorName = validatorData[validatorData.length - 1]?.name;
            const validatorWeb =
                validatorData[validatorData.length - 1]?.web_url;
            const validatorDesc =
                validatorData[validatorData.length - 1]?.description;

            // console.log(validatorDesc);
            setValidator({
                validatorName,
                validatorLogo,
                validatorWeb,
                validatorDesc,
            });
        }
    }, [validatorData]);
    useEffect(() => {
        // validatorData her değiştiğinde logoError'ı sıfırla
        setLogoError(false);
    }, [validatorData]);

    return (
        <>
            <InfoWrapper>
                <FirstWrapper>
                    <InfoLogo>
                        {!logoError && validator.validatorLogo ? (
                            <StyledImg
                                src={validator.validatorLogo}
                                alt={validator.validatorName}
                                onError={() => {
                                    setLogoError(true);
                                }}
                            />
                        ) : (
                            validator.validatorName &&
                            validator.validatorName.slice(0, 2)
                        )}
                    </InfoLogo>
                    <div> {validator.validatorName}</div>
                    <a
                        style={{
                            color: '#02c78c',
                            style: 'none',
                            display: 'block',
                        }}
                        href={validator.validatorWeb}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {' '}
                        <LanguageOutlinedIcon
                            sx={{ marginTop: '6px', fontSize: 16 }}
                        />
                    </a>
                </FirstWrapper>
                {/* <a href>{validator.validatorWeb} </a> */}
                <div>{validator.validatorDesc}</div>
            </InfoWrapper>
        </>
    );
};

export default ValidatorInfo;
