import React, { useState, useEffect } from 'react';
import { Border } from '../styles/border';
import { PageLayout } from '../components/layout/PageLayout';
import styled from 'styled-components';
import { RiComputerLine } from 'react-icons/ri';
import { FaNetworkWired, FaCheck, FaExclamation } from 'react-icons/fa';
import { IoIosTimer } from 'react-icons/io';
import { SpinnerBig } from '../styles/spinner';
import { ImInfo } from 'react-icons/im';
import { MdOutlineDns } from 'react-icons/md';
import { loadMonitoringStats } from '../scriptsApi/monitoring';
import { Helmet } from 'react-helmet-async';
const SpinnerOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    @media (min-width: 80rem) {
        flex-direction: row;
    }
`;

const Check = styled(Border)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    flex: 1 1 0%;
    padding: 18px 25px;

    @media (min-width: 80rem) {
        max-width: 500px;
    }
`;

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1 1 0%;
    /* padding: 18px 25px; */
`;

const Results = styled(Border)`
    /* z-index: 9999; */
    position: relative;
    flex: 1 1 0%;
    gap: 16px;
    padding: 18px 25px;
    justify-content: center;
    background-color: ${(p) => p.theme.widgetBackgroundColor};
`;

const Input = styled.input`
    width: 100%;
    padding: 8px 12px;
    /* flex: 1; */
    font-size: 16px;
    color: ${(p) => p.theme.inputColor};
    /* border: 1px solid #688881; */
    border-radius: 4px;
    /* outline: none; */
    background-color: ${(p) => p.theme.searchBackground};
    border: 1px solid ${(p) => p.theme.searchBorderColor};
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &[type='number'] {
        -moz-appearance: textfield;
    }
    &::placeholder {
        color: ${(p) => p.theme.placeholderColor};
    }
    &:focus {
        outline: 1px solid ${(p) => p.theme.buttonColor};
    }
`;

const Select = styled.select`
    position: relative;
    padding: 8px 12px;
    background-color: ${(p) => p.theme.searchBackground};
    border: 1px solid ${(p) => p.theme.searchBorderColor};
    color: ${(p) => p.theme.inputColor};
    /* margin: 10px; */
    width: 100%;
    height: 36px;
    outline: none;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    /* display: none; */
`;

const Button = styled.button`
    padding: 8px 64px;
    margin: 16px 0px;
    align-self: center;
    /* background-color: transparent; */
    /* border: none; */
    cursor: pointer;
    border-radius: 4px;
    font-size: 1.5rem;
    color: ${(p) => p.theme.bodyFontColor};
    background-color: ${(p) => p.theme.tabItemColor};
    border: 1px solid ${(p) => p.theme.searchBorderColor};
    &:hover {
        /* background-color: ${(p) => !p.noHover && p.theme.selectColor}; */
        color: ${(p) => p.theme.accordionOpenTitle};
    }
`;

const ItemWrapper = styled.div`
    border: 1px solid ${(p) => p.theme.searchBorderColor};
    /* border: 0.1px solid ${(p) => p.theme.widgetBorderColor}; */
    display: flex;
    flex-direction: column;
    background-color: ${(p) => p.theme.tabItemColor};
    gap: 4px;
    padding-bottom: 8px;
`;

const ItemHead = styled.div`
    border: 0.7px solid ${(p) => p.theme.searchBorderColor};
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 8px 16px;
    /* border: 0.1px solid ${(p) => p.theme.widgetBorderColor}; */
    background-color: ${(p) => p.theme.tabTitleColor};
    /* color: rgba(46, 65, 88, 0.812); */
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 0px 16px;
    margin-top: 8px;

    /* border: 0.1px solid ${(p) => p.theme.widgetBorderColor}; */
`;

const LeftS = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
`;

const RightS = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    /* font-size: 1.2rem; */
`;

const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`;

const PercentageText = styled.span`
    color: ${(p) => p.theme.bodyFontColor};
    /* color: #fff; */
    display: flex;
    justify-content: center;
    /* align-items: center; */
    font-size: 12px;
    /* padding: 8px 0; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    pointer-events: none;
`;

const ProgressBar = styled.progress`
    width: 100%;
    height: 20px;
    /* margin-top: 4px; */
    font-size: 2rem;
    /* accent-color: #54c9a8; */

    &[value] {
        --color: ${(p) => p.theme.progressColor};
        --background: ${(p) => p.theme.tabTitleColor};

        border: none; /* Firefox add a default border */
        /* width: 200px; */
        /* margin: 0 10px; */
        border-radius: 4px;
        background: var(--background);
    }
    &[value]::-moz-progress-bar {
        border-radius: 4px;
        background: var(--color);
    }
    &[value]::-webkit-progress-bar {
        border-radius: 4px;
        background: var(--background);
    }
    &[value]::-webkit-progress-value {
        border-radius: 4px;
        background: var(--color);
    }
`;

const AppWrapper = styled.div`
    /* align-self: flex-end; */
    /* gap: 8px; */
    align-items: flex-end;
    justify-content: flex-end;
    height: auto;
    display: flex;
    flex-direction: column;
    flex: 0 1 0%;
    img {
        height: 2rem;
        cursor: pointer;
    }
    @media (max-width: 80rem) {
        /* max-width: 150px; */
        flex-direction: row;
        gap: 4px;
    }
    @media (max-width: 32rem) {
        /* max-width: 150px; */
        flex-direction: column;
        gap: 4px;
    }
    /* flex-direction: column; */
`;

const AppText = styled.span`
    font-size: 0.875rem;
    flex: 1 1 0%;
`;

const SuigoMobileWrapper = styled.div`
    gap: 1rem;
    display: flex;
    align-items: center;
    border: 1px solid ${(p) => p.theme.searchBorderColor};
    padding: 8px 16px;
    /* flex-direction: column; */
    /* justify-self: flex-end; */
`;

export default function NodeMonitoring() {
    const [portError, setPortError] = useState(null);
    const [error, setError] = useState(null);
    const [versionMessage, setVersionMessage] = useState(null);
    const [syncMessage, setSyncMessage] = useState(null);
    const [ipAddress, setIpAddress] = useState('');
    const [port, setPort] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [network, setNetwork] = useState('Mainnet');
    const initialState = {
        epoch: null,
        suiVer: null,
        yourNodeVer: null,
        suiTxs: null,
        yourNodeTxs: null,
    };
    const [nodeInfo, setNodeInfo] = useState(initialState);

    function progressStatus(yourNodeTxs, suiTxs) {
        if (!yourNodeTxs || !suiTxs) return 0;
        const percentage = (yourNodeTxs / suiTxs) * 100;
        return isNaN(percentage) ? 0 : parseFloat(percentage.toFixed(2));
    }

    function compareVersions(version1, version2) {
        if (!version1 || !version2) {
            return null;
        }
        const v1parts = version1.split('.').map(Number);
        const v2parts = version2.split('.').map(Number);

        for (let i = 0; i < v1parts.length; i++) {
            if (v1parts[i] > v2parts[i]) {
                return 1;
            } else if (v1parts[i] < v2parts[i]) {
                return -1;
            }
        }
        return 0;
    }

    const checkNode = async () => {
        setNodeInfo(initialState);
        setSyncMessage(null);
        setVersionMessage(null);
        setError(null);
        setIsLoading(true); // Spinner goster
        const result = await loadMonitoringStats(ipAddress, port, network);
        // console.log('API Response:', result.data);
        setNodeInfo(result.data);
        setIsLoading(false); // Spinner gizle

        if (
            compareVersions(result.data.suiVer, result.data.yourNodeVer) ===
                1 ||
            compareVersions(result.data.suiVer, result.data.yourNodeVer) === -1
        ) {
            setVersionMessage(
                <div>
                    <FaExclamation style={{ color: '#e75c3d ' }} />
                    <span>
                        Your node version is different than blockchain one.
                    </span>
                </div>
            );
        }

        if (result.data.suiTxs - 1000 > result.data.yourNodeTxs) {
            const syncResult = (
                result.data.suiTxs - result.data.yourNodeTxs
            ).toLocaleString();
            // console.log(syncResult);
            setSyncMessage(
                <div>
                    <FaExclamation style={{ color: '#e75c3d ' }} />
                    Your node is{' '}
                    <span>
                        {'-'} {syncResult}
                        {'-'}
                    </span>{' '}
                    blocks behind the Sui Blockchain.
                </div>
            );
        }
    };

    function compareTxCounts(suiTxs, yourNodeTxs) {
        if (!suiTxs || !yourNodeTxs) return null;

        if (suiTxs - 1000 > yourNodeTxs) {
            return 1;
        } else if (suiTxs - 1000 <= yourNodeTxs) {
            return -1;
        }
        return 0;
    }

    const renderErrors = () => {
        const errors = nodeInfo.errors || {};

        const errorMessages = [];

        if (errors.userNodeServiceError) {
            errorMessages.push(errors.userNodeServiceError);
        }

        if (errors.userNodeTransactionError) {
            errorMessages.push(errors.userNodeTransactionError);
        }

        if (errors.suiServiceError) {
            errorMessages.push(errors.suiServiceError);
        }

        if (errors.unexpectedError) {
            errorMessages.push(errors.unexpectedError);
        }

        return (
            <div style={{ color: 'red' }}>
                {errorMessages.map((error, index) => (
                    <div key={index}>{error}</div>
                ))}
            </div>
        );
    };
    // const handlePortChange = (e) => {
    //     const value = e.target.value;
    //     if (value >= 0 && value <= 65535) {
    //         setPort(value);
    //     }
    // };

    const handlePortChange = (e) => {
        const value = e.target.value;
        if (value >= 0 && value <= 65535) {
            setPort(value);
            setPortError(null);
        } else {
            setPortError('Please enter a valid port number.');
        }
    };

    return (
        <PageLayout>
            <Helmet>
                <title>SuiGo - Node Checkpoint</title>
                <meta
                    property="og:url"
                    content="https://suigo.app/node-checkpoint"
                />
            </Helmet>
            <Wrapper></Wrapper>
            <Wrapper>
                <Check>
                    <InputWrapper>
                        Check your node
                        <Input
                            placeholder="IP Address / Node Url"
                            value={ipAddress}
                            onChange={(e) => setIpAddress(e.target.value)}
                            minlength="4"
                        />
                        <Input
                            placeholder="Port"
                            type="number"
                            value={port}
                            onChange={handlePortChange}
                            // onChange={(e) => setPort(e.target.value)}
                        />
                        <Select
                            value={network}
                            onChange={(e) => setNetwork(e.target.value)}
                        >
                            {/* <option value="">--Please choose an network--</option> */}
                            <option>Mainnet</option>
                            <option>Testnet</option>
                            <option>Devnet</option>
                        </Select>
                        <Button onClick={checkNode}>Check</Button>
                        {renderErrors()}
                        {portError && (
                            <div style={{ color: 'red' }}>{portError}</div>
                        )}
                        <div>{syncMessage}</div>
                        <div>{versionMessage}</div>
                    </InputWrapper>

                    <SuigoMobileWrapper>
                        <AppText>
                            {' '}
                            Instantly check the current status of your node,
                            including transaction sync progress, and receive
                            push notification alerts.
                        </AppText>
                        <AppWrapper>
                            <a href="https://apps.apple.com/tr/app/suigo/id6446952212">
                                <img
                                    src={'./images/appapp.png'}
                                    alt="Logo"
                                    loading={'eager'}
                                />
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.gateomega.suigo&pli=1">
                                <img
                                    src={'./images/gglapp.png'}
                                    alt="Logo"
                                    loading={'eager'}
                                />
                            </a>
                        </AppWrapper>
                    </SuigoMobileWrapper>
                </Check>
                <Results>
                    Results
                    <ItemWrapper>
                        <ItemHead>
                            <LeftS>
                                {/* <FaNetworkWired /> */}
                                <span>Info</span>
                            </LeftS>
                            <RightS>
                                <ImInfo />
                            </RightS>
                        </ItemHead>
                        <Item>
                            <LeftS>
                                <FaNetworkWired />
                                <span>Network</span>
                            </LeftS>
                            <RightS>{network}</RightS>
                        </Item>
                        <Item>
                            <LeftS>
                                <IoIosTimer />
                                <span>Epoch</span>
                            </LeftS>
                            <RightS>{nodeInfo.epoch} </RightS>
                        </Item>
                        <Item>
                            <LeftS>
                                <MdOutlineDns />
                                <span>IP Address</span>
                            </LeftS>
                            <RightS>{ipAddress}</RightS>
                        </Item>
                    </ItemWrapper>
                    <ItemWrapper>
                        <ItemHead>
                            <LeftS>
                                <span>Version</span>
                            </LeftS>
                            <RightS>
                                {compareVersions(
                                    nodeInfo.suiVer,
                                    nodeInfo.yourNodeVer
                                ) === 0 ? (
                                    <FaCheck style={{ color: '#06e4a5 ' }} />
                                ) : compareVersions(
                                      nodeInfo.suiVer,
                                      nodeInfo.yourNodeVer
                                  ) === null ? null : (
                                    <FaExclamation
                                        style={{ color: '#e4063a ' }}
                                    />
                                )}
                            </RightS>
                        </ItemHead>

                        <Item>
                            <LeftS>
                                <img
                                    src="./images/sui_logo (1).ico"
                                    width="16px"
                                    alt="Logo"
                                    loading={'eager'}
                                />
                                <span>Sui Version</span>
                            </LeftS>
                            <RightS>
                                <span>
                                    {nodeInfo.suiVer ? nodeInfo.suiVer : '-'}
                                </span>
                            </RightS>
                        </Item>
                        <Item>
                            <LeftS>
                                <RiComputerLine />
                                <span>Node Version</span>
                            </LeftS>
                            <RightS>
                                <span>
                                    {nodeInfo.yourNodeVer
                                        ? nodeInfo.yourNodeVer
                                        : '-'}
                                </span>
                            </RightS>
                        </Item>
                    </ItemWrapper>
                    <ItemWrapper>
                        <ItemHead>
                            <LeftS>
                                <span>Sync</span>
                            </LeftS>

                            <RightS>
                                {compareTxCounts(
                                    nodeInfo.suiTxs,
                                    nodeInfo.yourNodeTxs
                                ) === -1 ? (
                                    <FaCheck style={{ color: '#06e4a5 ' }} />
                                ) : compareTxCounts(
                                      nodeInfo.suiTxs,
                                      nodeInfo.yourNodeTxs
                                  ) === null ? null : (
                                    <FaExclamation
                                        style={{ color: '#e4063a ' }}
                                    />
                                )}
                            </RightS>
                        </ItemHead>
                        <Item>
                            <LeftS>
                                <img
                                    src="./images/sui_logo (1).ico"
                                    width="16px"
                                    alt="Logo"
                                    loading={'eager'}
                                />
                                <span>Sui Checkpoint</span>
                            </LeftS>
                            <RightS>
                                <span>
                                    {' '}
                                    {nodeInfo.suiTxs
                                        ? Number(
                                              nodeInfo.suiTxs
                                          ).toLocaleString()
                                        : '-'}
                                </span>
                            </RightS>
                        </Item>
                        <Item style={{ marginBottom: '8px' }}>
                            <LeftS>
                                <RiComputerLine />
                                <span>Node Checkpoint</span>
                            </LeftS>
                            <RightS>
                                <span>
                                    {nodeInfo.yourNodeTxs
                                        ? Number(
                                              nodeInfo.yourNodeTxs
                                          ).toLocaleString()
                                        : '-'}
                                </span>
                            </RightS>
                        </Item>
                    </ItemWrapper>
                    <ProgressBarContainer>
                        {/* <ProgressBar value={progressStatus} max="100" /> */}
                        <ProgressBar
                            value={progressStatus(
                                nodeInfo.yourNodeTxs,
                                nodeInfo.suiTxs
                            )}
                            max="100"
                        />
                        <PercentageText>{`${progressStatus(
                            nodeInfo.yourNodeTxs,
                            nodeInfo.suiTxs
                        )} %`}</PercentageText>
                    </ProgressBarContainer>
                    {isLoading && (
                        <SpinnerOverlay>
                            <SpinnerBig />
                        </SpinnerOverlay>
                    )}
                </Results>
            </Wrapper>
        </PageLayout>
    );
}

// function nodeStatus(yourNodeVer, suiVer) {
//     if (nodeInfo) {
//         const verResult = compareVersions(
//             nodeInfo.suiVer,
//             nodeInfo.yourNodeVer
//         );
//         console.log(verResult);
//         return verResult;
//     }
// }
