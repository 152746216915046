const theme = {
    id: 'light',
    primaryColor: '',
    secondaryColor: '',
    bodyBackgroundColor: '#ffffff',
    bodyFontColor: '#20242d',
    // selectColor: '#3a485110',
    selectColor: '#f0f0f0',
    toggleColor: '#20242d',
    widgetBackgroundColor: '#ffffff66',
    widgetBorderColor: '#35425925',
    buttonColor: '#20242D',
    searchBackground: '#F8F6F4',
    searchBorderColor: '#dddddd',
    placeholderColor: '#22554a55',
    inputColor: '#20242D',
    tableColor: '#0164d5',
    accordionTitle: '#20242D',
    accordionOpenTitle: '#20242D',
    accordionTitleBackground: '#F5F5F5',
    accordionContent: '#20242d',
    accordionContentBackground: '#ffffff',
    tabTitleColor: '#D8D9DA',
    tabItemColor: '#F8F6F4',
    progressColor: '#54c9a8',
    alertToggleBackground: 'rgba(0, 0, 0, 0.5)',
};

export default theme;
