import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
from{
transform: rotate(0deg);
}
to{
transform: rotate(360deg)

}
`;

const Spinner = styled.div`
    height: 15px;
    width: 15px;
    border: 2px solid #0ebb7e;
    border-radius: 50%;
    border-top: none;
    border-right: none;
    margin: 16px auto;
    animation: ${rotation} 1s linear infinite;
`;

export const SpinnerBig = styled.div`
    height: 32px;
    width: 32px;
    border: 3px solid #0ebb7e;
    border-radius: 50%;
    border-top: none;
    border-right: none;
    margin: 16px auto;
    animation: ${rotation} 1s linear infinite;
`;

export const SpinnerOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; // Or any high value to ensure it overlays other components
`;

export { Spinner };
