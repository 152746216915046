import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useTheme } from 'styled-components';

const DelegatorPieChart = ({ stakesData, style }) => {
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({});
    const theme = useTheme();
    useEffect(() => {
        // console.log('stakesData prop updated:', stakesData);
        const seriesData = stakesData.map((validator) => {
            const principal = validator.stakes.reduce(
                (sum, stake) =>
                    sum + Number((stake.principal / 1000000000).toFixed(2)),
                0
            );
            const estimatedReward = validator.stakes.reduce(
                (sum, stake) =>
                    sum +
                    Number((stake.estimatedReward / 1000000000).toFixed(2)),
                0
            );
            return {
                name: validator.name,
                value: principal + estimatedReward,
            };
        });
        // console.log(seriesData);
        setData(seriesData);
    }, [stakesData]);

    useEffect(() => {
        setOptions({
            title: {
                text: 'Stake Distribution',
                subtext: 'by Validator',
                bottom: 'left',
                show: true,
                textStyle: {
                    color: theme.bodyFontColor, // Set title text color
                },
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)',
            },
            legend: {
                orient: 'vertical',
                left: 'left',
                textStyle: {
                    color: theme.bodyFontColor, // Set legend text color
                },
            },
            series: [
                {
                    name: 'Validator',
                    type: 'pie',
                    radius: '50%',
                    data: data,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            // shadowColor: 'rgba(0, 0, 0, 0.5)',
                        },
                    },
                    label: {
                        color: theme.bodyFontColor, // etiketlerin rengi
                        borderColor: 'transparent', // etiketin çevresindeki border rengi
                    },
                },
            ],
        });
    }, [data, theme]);

    return (
        <ReactEcharts
            option={options}
            style={{
                height: '100%',
                width: '100%',
            }}
        />
    );
};

export default DelegatorPieChart;
