import { useRive, Layout, Fit, Alignment } from '@rive-app/react-canvas';
import styled from 'styled-components';

const RiveWrapper = styled.div`
    width: 128px;
    height: 128px;
`;

export default function LoadingRive() {
    const { RiveComponent } = useRive({
        src: 'images/deneme.riv',

        layout: new Layout({
            fit: Fit.FitWidth, // Change to: rive.Fit.Contain, or Cover
            alignment: Alignment.Center,
        }),
        autoplay: true,
    });

    return (
        <RiveWrapper>
            <RiveComponent />
        </RiveWrapper>
    );
}
