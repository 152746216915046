import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
html {
    font-size: 13px;

    @media (min-width: 64rem) {
        font-size: 16px;
    }

}

#root {
    display: flex;
    width: 100%;
    position: relative;
    min-height:100%;
    flex-direction:column;

    @media (min-width: 64rem) {
        flex-direction:row;
    }
}

*, *::before, *::after {
    box-sizing:border-box;
}

body {
    background: ${(p) => p.theme.bodyBackgroundColor};
    width: 100%;
    height: 100%;
    color:${(p) => p.theme.bodyFontColor};
    font-family: 'Montserrat', sans-serif;
    display:block;
    padding:0;
    margin: 0;

    ::-webkit-scrollbar {
        width: 2px;
    }
    ::-webkit-scrollbar-track {
        background: #dedede;
    }
}

.recharts-layer .recharts-bar-rectangle:hover {
    fill-opacity: 1 !important;
}
`;
