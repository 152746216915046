import styled from 'styled-components';
import { IoWalletOutline } from 'react-icons/io5';
import { ConnectButton } from '@suiet/wallet-kit';

export const Navbari = styled.nav`
    position: relative;
    flex-direction: column;
    display: none;
    /* height: 86px; */
    /* border: 0.5px solid rgb(219, 218, 218, 0.1); */
    /* display: flex; */
    /* align-items: center; */
    /* font-size: 16px; */
    /* color: #555; */
    z-index: 99;
    /* margin-bottom: 2.8rem; */
    color: ${(p) => p.theme.bodyFontColor};
    @media screen and (min-width: 64rem) {
        display: flex;
        border-radius: 12px;
    }
`;

export const NavWrapper = styled.div`
    padding: 16px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const WalletWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    margin-right: 14px;
    border: 1px solid #14daa246;
    border-radius: 10px;
    :hover {
        /* background-color: ${(p) => p.theme.selectColor}; */
        border-radius: 10px;
    }
`;

export const Connect = styled(ConnectButton)`
    width: 100%;
    padding: 0;
    background-color: transparent;
    color: ${(p) => p.theme.buttonColor};
    user-select: none;
    :hover {
        /* color: ${(p) => p.theme.bodyFontColor}; */
        background-color: #fff;
        /* padding: 0; */
    }
`;

export const WalletIcon = styled(IoWalletOutline)`
    color: ${(p) => p.theme.buttonColor};
`;
