import React from 'react';
import styled from 'styled-components';

const NewToggleWrapper = styled.div`
    width: 50px;
    min-width: 50px;
    height: 25px;
    background-color: ${(p) => (p.$isAlertActive ? '#22554a' : '#636363')};
    border-radius: 25px;
    display: flex;
    /* margin: left; */
    border: 1px solid #688881;
`;
const NewAlertNotch = styled.div`
    height: 21px;
    width: 21px;

    background: ${(p) => (p.$isAlertActive ? '#eaeaea' : '#bfbfbf')};
    border-radius: 50%;
    margin-top: 1px;
    border: 1px solid #688881;
    transition: transform 0.1s linear;
    transform: translate(${(p) => (p.$isAlertActive ? '26px' : '1px')});
`;

const NewBottomSide = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;

const NewToggleAlert = ({ isAlertActive, onAlertToggle }) => {
    return (
        <NewBottomSide>
            <NewToggleWrapper
                $isAlertActive={isAlertActive}
                onClick={onAlertToggle}
            >
                <NewAlertNotch $isAlertActive={isAlertActive} />
            </NewToggleWrapper>
        </NewBottomSide>
    );
};

export default NewToggleAlert;
