import React, { useEffect, useState } from 'react';
import { DelegatorWidget } from '../../styles/delegatorInfoStyle';
import { loadNetworkStats } from '../../scriptsApi/networkStatsApi';

export const DelegatorInfo = ({ type, stakesData }) => {
    const [tdata, setTdata] = useState([]);
    const [price, setPrice] = useState(null);
    useEffect(() => {
        // console.log('stakesData prop updated:', stakesData);

        const formattedData = stakesData.map((validator) => {
            const principal = validator.stakes.reduce(
                (sum, stake) => sum + Number(stake.principal / 1000000000),
                0
            );

            const estimatedReward = validator.stakes.reduce(
                (sum, stake) =>
                    sum + Number((stake.estimatedReward || 0) / 1000000000),
                0
            );

            return {
                principal: principal,
                estimatedReward: estimatedReward,
                total: principal + estimatedReward,
            };
        });

        setTdata(formattedData);
    }, [stakesData]);

    const fetchNetworkStats = async () => {
        try {
            const data = await loadNetworkStats();
            setPrice(data.suiPrice);
            // console.log(data.suiPrice);
            window.__DATA_LOADER_CACHE__ = data;
        } catch (error) {
            console.error('Error fetching network stats:', error);
        }
    };

    useEffect(() => {
        fetchNetworkStats();
    }, []);

    const totalStake = tdata.reduce(
        (sum, validator) => sum + validator.total,
        0
    );
    const totalRewards = tdata.reduce(
        (sum, validator) => sum + validator.estimatedReward,
        0
    );

    const totalStakeValue = totalStake.toFixed(2) * price;
    const totalRewardsValue = totalRewards.toFixed(2) * price;
    let data;
    switch (type) {
        case 'totalStake':
            data = {
                title: 'Total Stake',
                amount: `${totalStake.toFixed(2)} SUI`,
            };
            break;
        case 'totalStakeValue':
            data = {
                title: 'Total Stake Value',
                amount: `${totalStakeValue.toFixed(2)} $`,
            };
            break;
        case 'lastEpochGain':
            data = {
                title: 'Total Stake Rewards ',
                amount: `${totalRewards.toFixed(
                    2
                )} SUI / ${totalRewardsValue.toFixed(2)} $ `,
            };
            break;
        default:
            break;
    }
    return (
        <DelegatorWidget>
            <h1>{data.title}</h1>
            <p>{data.amount}</p>
        </DelegatorWidget>
    );
};
