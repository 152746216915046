import styled from 'styled-components';

export const MainWrapper = styled.div`
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
    margin: 16px 16px;
    isolation: isolate;

    @media screen and (min-width: 64rem) {
        margin: 16px 16px 16px 240px;
    }
`;

export const Content = styled.main`
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 140rem;
    min-height: 100%;
    margin: 0px auto;
`;

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 12px;
    gap: 16px;
    position: relative;
    isolation: isolate;

    @media screen and (mind-width: 80rem) {
        grid-template-columns: auto 1fr;
    }
`;
export const WrapperMain = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 20px;
    border-radius: 12px;
`;
