import styled from 'styled-components';
import { Border } from './border';

export const DelegatorWidget = styled(Border)`
    flex: 1 1 0%;
    gap: 16px;
    padding: 18px 25px;
    -webkit-box-pack: center;
    justify-content: center;

    h1 {
        min-width: 0px;
        font-weight: 400;
        font-size: 1rem;
        color: #54c9a8;
    }

    p {
        margin: 4px 0px -6px;
        font-weight: 400;
        /* font-size: 1.5rem; */
    }
`;
