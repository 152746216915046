import React, { useState } from 'react';
import styled from 'styled-components';
import { Border } from '../../styles/border';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const AccordionTitleWrap = styled.div`
    display: flex;
    font-weight: ${(p) => (p.isOpen ? 600 : 400)};
    justify-content: space-between;
    align-items: center;
    border: 0.5px solid ${(p) => p.theme.widgetBorderColor};
    padding: 12px 16px;
    background: ${(p) => p.theme.accordionTitleBackground};
    color: ${(p) => p.theme.accordionTitle};

    background: ${(p) =>
        p.isOpen
            ? p.theme.openAccordionTitleBackground
            : p.theme.accordionTitleBackground};
    color: ${(p) =>
        p.isOpen ? p.theme.accordionOpenTitle : p.theme.accordionTitle};
`;

const AccordionContent = styled.div`
    padding: 16px 32px;
    height: auto;
    width: 100%;
    background: ${(p) => p.theme.accordionContentBackground};
    color: ${(p) => p.theme.accordionContent};
    line-height: 24px;
`;

const AccordionTitle = styled.div`
    user-select: none;
    height: auto;
    width: 100%;
`;

const AccordionWrap = styled.div`
    border-left: 1px solid ${(p) => p.theme.widgetBorderColor};
    display: flex;
    flex-direction: column;
    background-color: ${(p) => p.theme.widgetBackgroundColor};
    margin-bottom: 8px;
`;

export default function Accordion({ items }) {
    // Durum değişkenini başlangıçta ilk accordionun indeksine ayarlayabiliriz.
    const [openIndex, setOpenIndex] = useState(0);

    return (
        <div>
            {items.map((item, index) => (
                <AccordionItem
                    key={index}
                    title={item.title}
                    // isOpen propunu, mevcut indeksin openIndex'e eşit olup olmadığına göre ayarlayabiliriz.
                    isOpen={index === openIndex}
                    // Bir accordiona tıklanırsa, durum değişkenini güncelleriz.
                    // Eğer tıklanan accordion zaten açıksa, tüm accordionları kapatabiliriz.
                    onTitleClick={() =>
                        setOpenIndex(openIndex === index ? -1 : index)
                    }
                >
                    {item.content}
                </AccordionItem>
            ))}
        </div>
    );
}

function AccordionItem({ title, children, isOpen, onTitleClick }) {
    return (
        <AccordionWrap>
            <AccordionTitleWrap onClick={onTitleClick} isOpen={isOpen}>
                <AccordionTitle>{title}</AccordionTitle>
                {/* isOpen durumuna göre ikon değişiyor */}
                {isOpen ? <RemoveIcon /> : <AddIcon />}
            </AccordionTitleWrap>
            {isOpen && <AccordionContent>{children}</AccordionContent>}
        </AccordionWrap>
    );
}
