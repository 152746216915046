import React, { useEffect, useState, useContext } from 'react';
import { useWallet } from '@suiet/wallet-kit';
import { PageLayout } from '../components/layout/PageLayout';
import { DelegatorTable } from '../components/tables/DelegatorTable';
import { DelegatorInfo } from '../components/delegators/DelegatorInfo';
import { loadSingleDelegator } from '../scriptsApi/delegatorApi';
import { loadTableData } from '../scriptsApi/validatorTableApi';
import { SpinnerBig } from '../styles/spinner';
import { SpinnerOverlay } from '../styles/spinner';
import { Helmet } from 'react-helmet-async';
import DelegatorPieChart from '../components/charts/DelegatorPieChart';
import DelegatorPieRatio from '../components/charts/DelegatorPieRatio';
import {
    ModalSearchIcon,
    SearchStil,
    SearchButton,
    Chart,
    MainWrapper,
    DelInfoWrapper,
    ChartWrapper,
} from '../styles/myStakesStyle';

import LoadingRive from '../components/loading_rive';
// import Breadcrumbs from '../components/Breadcrumbs';

export default function MyStakes() {
    const [placeholderText, setPlaceholderText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    // const { connected, connect } = useWallet();
    const { connected, connect, status } = useWallet();
    const [searchAddress, setSearchAddress] = useState('');
    const [stakesData, setStakesData] = useState([]);
    // const { suiAddress, setSuiAddress } = useContext(AppContext);
    const wallet = useWallet();

    const fetchData = async (suiAddress) => {
        setIsLoading(true);
        try {
            const delegatorData = await loadSingleDelegator(suiAddress);
            // console.log(delegatorData);
            const { data: validatorData } = await loadTableData();

            // console.log(validatorData);

            const combinedData = validatorData
                .filter((validator) =>
                    delegatorData.some(
                        (delegator) =>
                            delegator.validatorAddress === validator.sui_address
                    )
                )
                .map((validator) => {
                    const firstApiValidator = delegatorData.find(
                        (v) => v.validatorAddress === validator.sui_address
                    );
                    return { ...validator, ...firstApiValidator };
                });

            setStakesData(combinedData);
            setIsLoading(false);

            // console.log(combinedData);
        } catch (error) {
            console.error('Error fetching data:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const lastWalletAddress = localStorage.getItem('lastWalletAddress');
        if (lastWalletAddress) {
            fetchData(lastWalletAddress);
            setSearchAddress(lastWalletAddress);
        }
    }, []);

    useEffect(() => {
        if (connected && wallet.address) {
            fetchData(wallet.address);
        }
    }, [connected, wallet.address]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await fetchData(searchAddress);
        localStorage.setItem('lastWalletAddress', searchAddress);
        setSearchAddress('');
    };

    useEffect(() => {
        const isMobile = window.innerWidth <= 512; // 32em

        if (isMobile) {
            setPlaceholderText('Enter your public key.');
        } else {
            setPlaceholderText(
                'Enter your public key or connect your wallet to access your stake information.'
            );
        }
    }, []);

    return (
        <PageLayout>
            {/* <Breadcrumbs /> */}
            <Helmet>
                <title>SuiGo - My Stakes</title>
                <meta property="og:url" content="https://suigo.app/mystakes" />
            </Helmet>
            <SearchStil onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={searchAddress}
                    onChange={(e) => setSearchAddress(e.target.value)}
                    placeholder={placeholderText}
                />
                <SearchButton type="submit">
                    <ModalSearchIcon />
                </SearchButton>
            </SearchStil>
            {stakesData ? (
                <>
                    <MainWrapper>
                        <DelInfoWrapper>
                            <DelegatorInfo
                                stakesData={stakesData}
                                type="totalStake"
                            />
                            <DelegatorInfo
                                stakesData={stakesData}
                                type="totalStakeValue"
                            />
                            <DelegatorInfo
                                stakesData={stakesData}
                                type="lastEpochGain"
                            />
                        </DelInfoWrapper>
                        {/* <ChartWrapper> */}
                        <Chart>
                            <ChartWrapper>
                                <DelegatorPieChart
                                    stakesData={stakesData}
                                    style={{
                                        color: (p) => p.theme.bodyFontColor,
                                    }}
                                />
                            </ChartWrapper>
                        </Chart>
                        <Chart>
                            <ChartWrapper>
                                <DelegatorPieRatio
                                    stakesData={stakesData}
                                    style={{
                                        color: (p) => p.theme.bodyFontColor,
                                    }}
                                />
                            </ChartWrapper>
                        </Chart>
                        {/* </ChartWrapper> */}
                    </MainWrapper>
                    <DelegatorTable stakesData={stakesData} />
                </>
            ) : null}
            {isLoading && (
                <SpinnerOverlay>
                    {' '}
                    <LoadingRive />
                </SpinnerOverlay>
                // <SpinnerOverlay>
                //     <SpinnerBig />
                // </SpinnerOverlay>
            )}
        </PageLayout>
    );
}
