export const items = [
    {
        uuid: '1',
        title: 'What is Suigo.app?',
        content:
            'SuiGo.app - Your Entry Point to Sui" aims to enhance user experience by offering data insights, monitoring, and alerting tools. It serves as a go-to space for validators, delegators, and newcomers.',
    },
    {
        uuid: '2',
        title: 'Where does Suigo.app source its data from?',
        content:
            'Suigo.app retrieves its data directly from the Sui blockchain, RPC nodes, and indexer nodes. But potential delays might occur in the data due to various reasons related to data retrieval.',
    },
    {
        uuid: '3',
        title: 'What kind of data is generally displayed on the platform?',
        content:
            'The platform primarily displays validator-specific data such as total stake, commission, and APY, as well as general data about the Sui blockchain.',
    },
    {
        uuid: '4',
        title: 'What is the primary purpose of the Node Checkpoint page?',
        content:
            'The Node Checkpoint page is designed primarily for validators and full node operators. By entering node URL and port details, validators can ascertain if their nodes are synchronized with the actual blockchain data. Users can conduct comparisons across three distinct chains: Mainnet, Testnet, and Devnet.',
    },

    {
        uuid: '6',
        title: 'What can users expect to see on the My Stakes page?',
        content:
            'The "My Stake" page provides users with detailed information about their stakes in various validators, including the amount staked, the validators they`ve staked in, and their estimated earnings. Simply click on the "Connect Wallet" button and follow the on-screen instructions to link your wallet. Once linked, the page will automatically display your stake details.The distribution of your stakes is visually represented in a chart on the "My Stake" page. This gives you a clear overview of how your stakes are distributed among different validators.',
    },
    {
        uuid: '7',
        title: 'Can I search for my wallet address directly without connecting my wallet?',
        content:
            'Yes, you can use the search bar at the top of the page to enter your wallet address. After submitting, your staking details will be displayed.',
    },
    {
        uuid: '8',
        title: 'How is the estimated earning calculated?',
        content:
            "The estimated earnings are calculated based on the amount you've staked, the Annual Percentage Yield (APY) of the validator, and the validator's commission rate.",
    },
    {
        uuid: '9',
        title: "What does APY mean in the context of staking? What is a validator's commission?",
        content:
            "APY stands for Annual Percentage Yield. It represents the estimated annual return on your stake, taking into account the effect of compounding. A validator's commission is a fee that the validator charges for their services. It's deducted from the rewards before they're distributed to the stakers.",
    },
    {
        uuid: '10',
        title: 'Can I see historical data about my stakes?',
        content:
            'Currently, the "My Stake" page shows the present state of your stakes. For historical data, you might need to use other tools or platforms, or directly check the blockchain records.',
    },
    {
        uuid: '11',
        title: 'About My Alerts page',
        content:
            'My alerts page is under development, but once the development is complete, users will have the choice of receiving alerts through email or Telegram. Users can set up alerts related to commission changes and the activity status of validators and more...',
    },
];
