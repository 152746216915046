import axios from 'axios';

export async function loadMonitoringStats(ip, port, chain) {
    try {
        const response = await axios.get(
            `https://suigo.app/api/v6/monitoring?ip=${ip}&port=${port}&chain=${chain}`
        );

        if (response.status === 200) {
            return {
                success: Object.keys(response.data.errors).length === 0, // Eğer hata objesi boşsa success'i true olarak belirle
                data: response.data,
            };
        } else {
            return {
                success: false,
                data: {
                    errors: { serverError: 'Unable to reach server' },
                },
            };
        }
    } catch (error) {
        return {
            success: false,
            data: {
                errors: {
                    unexpectedError:
                        'An unexpected error occurred. Set the correct address and port number. ',
                },
            },
        };
    }
}
