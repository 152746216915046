import { loadEpochStats } from './epochApi';

export const loadTableData = async () => {
    try {
        // Öncelikle son epoch numarasını al
        const epochStats = await loadEpochStats();
        const latestEpoch = epochStats.epoch; // son epoch numarası
        // console.log(latestEpoch);
        // Sonra son epoch'a ait verileri al
        const dataResponse = await fetch(
            `https://suigo.app/api/v1/validators?epoch=${latestEpoch}`
        );
        const json = await dataResponse.json();

        if (Array.isArray(json)) {
            return { data: json, meta: { totalRowCount: json.length } };
        } else {
            return json;
        }
    } catch (error) {
        console.error('Error fetching table data:', error);
    }
};
