import React from 'react';
import { useWallet } from '@suiet/wallet-kit';
import {
    Navbari,
    NavWrapper,
    WalletWrapper,
    Connect,
    WalletIcon,
} from '../../styles/navbarStyle';

export function Navbar({ children }) {
    const { connected, connect, status } = useWallet();

    const handleConnectWallet = () => {
        if (status === 'disconnected') {
            // Eğer cüzdan bağlı değilse.
            connect();
        } else if (status === 'connected') {
            // Cüzdan zaten bağlı.
        }
    };
    return (
        <>
            <Navbari>
                <NavWrapper>
                    <WalletWrapper>
                        <WalletIcon />
                        <Connect onClick={handleConnectWallet}>
                            Wallet Connect
                        </Connect>
                    </WalletWrapper>
                </NavWrapper>
            </Navbari>
        </>
    );
}
// {wallet.connected ? <AccountInfo address={wallet?.address} /> : <Connect onConnectSuccess>Connect Wallet</Connect>}
