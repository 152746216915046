import React, { useState, useEffect } from 'react';
import {
    AreaChart,
    Area,
    Tooltip,
    ResponsiveContainer,
    YAxis,
    XAxis,
} from 'recharts';
import { loadSuiPriceStats } from '../../scriptsApi/networkStatsApi';

const SuiPriceChart = () => {
    const [priceData, setPriceData] = useState([]);
    const formatTooltipLabel = (value) => {
        const date = new Date(value);
        return date.toLocaleTimeString([], {
            day: '2-digit',
            month: 'short',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
        });
    };

    const formatTooltipValue = (value, name) => {
        // name'in 'current_tps' olması durumunda 'tps' olarak değiştiriyoruz
        const formattedName = name === 'suiprice' ? 'Sui' : name;

        return [value, formattedName];
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const formattedDate = formatTooltipLabel(label);
            const [value, name] = formatTooltipValue(
                payload[0].value,
                payload[0].name
            );
            return (
                <div
                    style={{
                        position: 'relative',
                        bottom: '48px',
                        backgroundColor: 'transparent',
                        padding: '8px',
                    }}
                >
                    <p style={{ marginBottom: '1px', fontSize: '12px' }}>
                        {formattedDate}
                    </p>
                    <p style={{ fontSize: '14px' }}>{`${name}: ${value}`} $</p>
                </div>
            );
        }
        return null;
    };

    const fetchPriceStats = async () => {
        try {
            const data = await (window.__SUI_DATA_LOADER_CACHE__ ||
                loadSuiPriceStats());
            setPriceData(data.dataPrice);
        } catch (error) {
            console.error('Error fetching TPS stats:', error);
        }
    };
    useEffect(() => {
        fetchPriceStats();

        const intervalId = setInterval(() => {
            fetchPriceStats();
        }, 60000); // her 10 dakika

        // clear interval
        return () => clearInterval(intervalId);
    }, []);

    if (!priceData.length) {
        return <div>Yükleniyor...</div>;
    }

    return (
        <div style={{ width: 180, height: 50 }}>
            <ResponsiveContainer>
                <AreaChart
                    data={priceData}
                    margin={{
                        top: 5,
                        right: 0,
                        left: 0,
                        bottom: 5,
                    }}
                >
                    <defs>
                        <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="5%"
                                stopColor="#54c9a8"
                                stopOpacity={1}
                            />
                            <stop
                                offset="25%"
                                stopColor="#54c9a8"
                                stopOpacity={0.3}
                            />
                            <stop
                                offset="95%"
                                stopColor="#54c9a8"
                                stopOpacity={0}
                            />
                        </linearGradient>
                    </defs>
                    <XAxis
                        domain={['auto', 'auto']}
                        dataKey="date_time"
                        hide={true}
                    />
                    <YAxis domain={['auto', 'auto']} hide={true} />

                    <Area
                        type="monotone"
                        dataKey="suiprice"
                        stroke="#54c9a8"
                        fill="url(#colorUv)"
                    />
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Tooltip
                        labelFormatter={formatTooltipLabel}
                        formatter={formatTooltipValue}
                        wrapperStyle={{ outline: 'none' }}
                        contentStyle={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            fontSize: '12px',
                            position: 'relative',
                            bottom: '48px',
                        }}
                    /> */}
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SuiPriceChart;
