import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { loadValidatorStatus } from '../../scriptsApi/statusApi';
import { loadNetworkStats } from '../../scriptsApi/networkStatsApi';
import { Spinner } from '../../styles/spinner';
import {
    LeftS,
    Stats,
    Item,
    RightS,
    ProgressBarContainer,
    PercentageText,
    ProgressBar,
} from '../../styles/mainStatsStyle';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const CustomTooltip = styled(ReactTooltip)`
    background: ${(p) => p.theme.bodyFontColor} !important;
    font-size: 11px !important;
    overflow-wrap: break-word;
    max-width: 300px;
    padding: 4px 4px !important;
    color: ${(p) => p.theme.bodyBackgroundColor} !important;
    z-index: 15;
`;

export const MainStats = () => {
    const [networkStats, setNetworkStats] = useState(null);
    const [validatorNumber, setValidatorNumber] = useState(null);

    const fetchNetworkStats = async () => {
        try {
            const data = await loadNetworkStats();
            setNetworkStats(data);
            window.__DATA_LOADER_CACHE__ = data;
        } catch (error) {
            console.error('Error fetching network stats:', error);
        }
    };

    const fetchValidatorNumber = async () => {
        try {
            const data = await loadValidatorStatus();
            const numberValidators = data.length;
            setValidatorNumber(numberValidators);
            window.__VALIDATOR_UPTIME_LOADER_CACHE__ = data;
        } catch (error) {
            console.error('Error fetching network stats:', error);
        }
    };

    function MyTooltip({ children, title }) {
        const [show, setShow] = useState(false);

        return (
            <div
                onTouchStart={() => setShow(true)}
                onTouchEnd={() => setShow(false)}
                onTouchCancel={() => setShow(false)}
            >
                {show && <div style={{ position: 'absolute' }}>{title}</div>}
                {children}
            </div>
        );
    }

    useEffect(() => {
        fetchValidatorNumber();
        const intervalId = setInterval(() => {
            fetchValidatorNumber();
        }, 600000); // 10 dakika
        // clear interval
        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        fetchNetworkStats();

        const intervalId = setInterval(() => {
            fetchNetworkStats();
        }, 60000); // her 60 saniyede data cekimi

        // clear interval
        return () => clearInterval(intervalId);
    }, []);

    const renderData = (key, format) => {
        if (networkStats && networkStats[key]) {
            const value = networkStats[key];
            const formattedValue =
                format === 'toLocaleString'
                    ? Number(value).toLocaleString()
                    : value;
            return <span>{formattedValue}</span>;
        }
        return <Spinner />;
    };

    return (
        <Stats>
            <Item>
                <LeftS>
                    <span>Circulating Supply</span>
                    <InfoOutlinedIcon
                        data-tooltip-id="my-tooltip-1"
                        sx={{ fontSize: 12, color: 'grey' }}
                    />
                    <CustomTooltip
                        id="my-tooltip-1"
                        place="bottom"
                        content="The amount of coins that are circulating in the market and are in public hands."
                    />
                </LeftS>
                <RightS>
                    {networkStats && (
                        <ProgressBarContainer>
                            <PercentageText style={{ fontSize: '0.875rem' }}>
                                % {networkStats.suiCirRatio}{' '}
                                {renderData(
                                    'suiCirculatingSupply',
                                    'toLocaleString'
                                )}
                            </PercentageText>
                            <ProgressBar
                                value={networkStats.suiCirRatio}
                                max="100"
                            />
                        </ProgressBarContainer>
                    )}
                </RightS>
            </Item>

            <Item>
                <LeftS>
                    <span>Total Value Locked</span>
                    <InfoOutlinedIcon
                        data-tooltip-id="my-tooltip-2"
                        sx={{ fontSize: 12, color: 'grey' }}
                    />
                    <CustomTooltip
                        id="my-tooltip-2"
                        place="bottom"
                        content="The total value of assets locked into DeFi protocols."
                    />
                </LeftS>
                <RightS>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '5px' }}>$</span>
                        <span>{renderData('tvl', 'toLocaleString')}</span>
                    </div>
                </RightS>
            </Item>
            <Item>
                <LeftS>
                    <span>Total Stake</span>

                    <InfoOutlinedIcon
                        data-tooltip-id="my-tooltip-3"
                        sx={{ fontSize: 12, color: 'grey' }}
                    />
                    <CustomTooltip
                        id="my-tooltip-3"
                        place="bottom"
                        content="Total number of SUI tokens that are currently being staked."
                    />
                </LeftS>
                <RightS>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                            {renderData('totalStake', 'toLocaleString')}
                        </span>
                        <span style={{ marginLeft: '5px' }}> SUI</span>
                    </div>
                </RightS>
            </Item>
            <Item>
                <LeftS>
                    <span>Reference Gas Price</span>
                    <InfoOutlinedIcon
                        data-tooltip-id="my-tooltip-4"
                        sx={{ fontSize: 12, color: 'grey' }}
                    />
                    <CustomTooltip
                        id="my-tooltip-4"
                        place="bottom"
                        content="Reference gas price is a pivot point for setting transaction gas fees. Reference gas price is defined as a point of 2/3 percentile of gas prices validators submit each epoch."
                    />
                </LeftS>
                <RightS>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                            {renderData('referenceGasPrice', 'toLocaleString')}
                        </span>
                        <span style={{ marginLeft: '5px' }}> MIST</span>
                    </div>
                </RightS>
            </Item>
            <Item>
                <LeftS>
                    <span>Total TX Blocks</span>
                    <InfoOutlinedIcon
                        data-tooltip-id="my-tooltip-5"
                        sx={{ fontSize: 12, color: 'grey' }}
                    />
                    <CustomTooltip
                        id="my-tooltip-5"
                        place="bottom"
                        content="Total transaction blocks in Sui Network."
                    />
                </LeftS>
                <RightS>
                    <span>
                        {renderData('totalAddresses', 'toLocaleString')}
                    </span>
                </RightS>
            </Item>
            <Item>
                <LeftS>
                    <span>Total Validators</span>
                    <InfoOutlinedIcon
                        data-tooltip-id="my-tooltip-6"
                        sx={{ fontSize: 12, color: 'grey' }}
                    />
                    <CustomTooltip
                        id="my-tooltip-6"
                        place="bottom"
                        content="Total number of validators on Sui Blockchain."
                    />
                </LeftS>
                <RightS>
                    <span>{validatorNumber}</span>
                </RightS>
            </Item>
        </Stats>
    );
};
