import styled from 'styled-components';

export const Social = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-around;
    width: 50%;
    margin: -6px -6px 0px 6px;

    a {
        color: ${(p) => (p.isActive ? '#01c2d5' : p.theme.bodyFontColor)};
    }
    a:hover {
        color: #01c2d5;
    }

    div {
        hr {
            border-top: 1px solid red;
        }
    }
`;
