import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Border } from '../../styles/border';
// import SingleHealthChart from 'components/charts/SingleHealthCharts';

const ValidatorLeft = styled(Border)`
    flex: 1 1 0%;
    gap: 4px;
    padding: 18px 25px;
    -webkit-box-pack: center;
    justify-content: center;

    h1 {
        min-width: 0px;
        font-weight: 600;
        font-size: 1rem;
        color: #54c9a8;
    }

    p {
        margin: 4px 0px -6px;
        font-weight: 400;
        font-size: 0.75rem;
        color: ${(p) => p.theme.bodyFontColor};
    }
`;
const ValidatorStake = ({ validatorName, validatorData }) => {
    const [stake, setStake] = useState([]);

    useEffect(() => {
        if (validatorData) {
            const validatorEpoch = parseInt(
                validatorData[validatorData.length - 1]?.epoch
            );

            // console.log(validatorEpoch);
            let firstEpoch = parseInt(
                validatorData[validatorData.length - 1]?.first_epoch
            );
            // console.log(firstEpoch);
            firstEpoch = validatorEpoch - firstEpoch;
            // console.log(firstEpoch);
            let validatorStake = (
                validatorData[validatorData.length - 1]?.total_stake /
                1000000000
            ).toFixed(0);
            validatorStake = Number(validatorStake).toLocaleString();

            setStake({ validatorStake, firstEpoch });
        }
    }, [validatorData]);
    return (
        <>
            <ValidatorLeft>
                <h1>
                    Total Stake
                    <p style={{ marginLeft: '8px', display: 'inline-block' }}>
                        ( For {stake.firstEpoch} Epoch )
                    </p>
                </h1>

                <span>{stake.validatorStake} SUI</span>
            </ValidatorLeft>
        </>
    );
};

export default ValidatorStake;
