import axios from 'axios';

export const loadNetworkStats = async () => {
    try {
        const response = await axios.get(
            'https://suigo.app/api/v3/networkStats'
        );
        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching network stats:', error);
    }
};

export const loadTpsStats = async () => {
    try {
        const response = await axios.get(
            'https://suigo.app/api/v3/currentTpsStats'
        );

        const sortedData = response.data.data.sort(
            (a, b) => new Date(a.date_time) - new Date(b.date_time)
        );
        return {
            data: sortedData, // Tüm tps
            latest: sortedData[0], // En son tps
        };
    } catch (error) {
        console.error('Error fetching network stats:', error);
    }
};

export const loadSuiPriceStats = async () => {
    try {
        const response = await axios.get(
            'https://suigo.app/api/v3/suiPriceStats'
        );
        const sortedPriceData = response.data.data.sort(
            (a, b) => new Date(a.date_time) - new Date(b.date_time)
        );
        return {
            dataPrice: sortedPriceData, // Tum veri
            // latestPrice: sortedPriceData[0], // En son price
        };
    } catch (error) {
        console.error('Error fetching network stats:', error);
    }
};
