import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { useNavigate, Link } from 'react-router-dom';
import { Wrapper } from '../../styles/delegatorTableStyle';

const StyledLink = styled(Link)`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    text-align: start;
    margin: -6px 0px -6px -6px;
    padding: 6px;
    border-radius: 6px;
    text-decoration: none;
    font-weight: ${(p) => (p.isActive ? '600' : 'normal')};
    color: ${(p) => (p.isActive ? '#01c2d5' : p.theme.bodyFontColor)};
    background-color: ${(p) => p.isActive && p.theme.selectColor};

    &:hover {
        background-color: ${(p) => p.theme.selectColor};
    }
`;

export const DelegatorTable = ({ stakesData }) => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    // useEffect(() => {
    //     console.log('stakesData prop updated:', stakesData);

    //     setData(stakesData);
    //     console.log(stakesData);
    //     // const staky = stakesData.stakes.map((stake) => stake.estimatedReward);
    //     // console.log('dd', staky);
    // }, [stakesData]);

    useEffect(() => {
        // console.log('stakesData prop updated:', stakesData);

        const flattenedData = stakesData.flatMap((validator) =>
            validator.stakes.map((stake) => ({ ...validator, ...stake }))
        );

        setData(flattenedData);
        // console.log(flattenedData);
    }, [stakesData]);

    const columns = useMemo(
        () => [
            {
                id: 'row',
                Header: '#',
                width: 40,
                Cell: (row) => {
                    return <div>{row.row.index + 1}</div>;
                },
                // rowNumberMode: false,
                canSort: false,
            },
            {
                Header: 'Validators',
                accessor: 'name',
                width: 150,
                Cell: ({ cell, row }) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                            style={{
                                width: '25px',
                                height: '25px',
                                borderRadius: '50%',
                                backgroundColor: 'white',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'black',
                                overflow: 'hidden',
                                marginRight: '10px',
                            }}
                        >
                            {row.original.img_url ? (
                                <img
                                    src={row.original.img_url}
                                    alt=""
                                    style={{ width: '100%', height: '100%' }}
                                    onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '';
                                    }}
                                />
                            ) : (
                                row.original.name.slice(0, 2)
                            )}
                        </div>

                        <StyledLink to={`/${cell.value}`}>
                            {cell.value}
                        </StyledLink>
                    </div>
                ),
            },

            // {
            //     Header: 'GO Rating',
            //     accessor: 'go_rating',
            //     width: 100,
            //     Cell: ({ value }) => `${value / 100} %`,
            // },
            {
                Header: 'Commission',
                accessor: 'commission',
                width: 100,
                Cell: ({ value }) => `${value / 100} %`,
            },
            {
                Header: 'APY',
                accessor: 'apy',
                width: 100,
                Cell: ({ value }) => `${value} %`,
            },
            {
                Header: 'Delegated Stake',
                accessor: 'principal',
                width: 100,
                Cell: ({ value }) =>
                    `${new Intl.NumberFormat().format(
                        Math.floor(value / 1000000000)
                    )} SUI`,
            },

            {
                Header: 'Estimated Gain',
                accessor: (row) => row.estimatedReward ?? 0,
                width: 100,
                Cell: ({ value }) => `${(value / 1000000000).toFixed(2)} SUI`,
            },
            {
                Header: 'Activation Epoch',
                accessor: 'stakeActiveEpoch',
                width: 100,
            },
            {
                Header: 'Stake Status',
                accessor: 'status',
                width: 100,
            },
        ],
        []
    );
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data }, useGlobalFilter, useSortBy);
    return (
        <Wrapper>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                    style={{ width: column.width }}
                                >
                                    <span>{column.render('Header')} </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                onClick={() => {
                                    navigate(`/${row.original.name}`);
                                    window.scrollTo(0, 0);
                                }}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Wrapper>
    );
};
