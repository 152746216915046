import React, { useState, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
// import { Link as ReactRouterDomLink, useLocation } from 'react-router-dom';
import { MobileToggle } from '../toggle/ToggleMobile';
import {
    HeaderWrapper,
    Menu,
    MobileMenuIcon,
    MobilCircle,
} from '../../styles/headerStyle';
import { NavLink as ReactRouterDomLink, useLocation } from 'react-router-dom';
import GateOmegaMobile from '../gateomega/GateOmegaMobile';

const Reserved = styled.div`
    font-size: 12px;
`;

const NavLink = ({ isActive, children, setMenuOpen, ...props }) => {
    return (
        <ReactRouterDomLink {...props} onClick={() => setMenuOpen(false)}>
            {children}
        </ReactRouterDomLink>
    );
};

const DisabledLink = styled.span`
    padding: 8px 8px;
    width: 100%;
    display: block;
    text-align: center;
    margin: auto 0;
    text-decoration: none;
    color: grey;
    font-weight: normal;
    font-size: 1.5rem;
    cursor: default;
`;

const StyledLink = styled(NavLink)`
    padding: 8px 8px;
    width: 100%;
    display: block;
    text-align: center;
    margin: auto 0;
    text-decoration: none;
    color: ${(p) => p.theme.bodyFontColor};
    font-weight: ${(p) => (p.$isActive ? 'bold' : 'normal')};
    font-size: 1.5rem;

    &:hover {
        background-color: ${(p) => p.theme.selectColor};
    }
`;

const RightWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 16px;
    /* margin-right: 14px; */
`;

const SLogo = styled.div`
    margin-top: 12px;
    img {
        height: 32px;
        padding-left: 4px;
    }
`;

export function Header() {
    const { pathname } = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);
    const { id, setTheme } = useContext(ThemeContext);
    return (
        <HeaderWrapper style={{ height: '100%' }}>
            <SLogo>
                <a href="https://suigo.app">
                    <img
                        src={
                            id === 'dark'
                                ? './images/suigo_white.png'
                                : './images/suigo_black.png'
                        }
                        alt="Logo"
                        loading={'eager'}
                    />
                </a>
                <div>
                    <p
                        style={{
                            fontSize: '12px',
                            color: '#06bcef',
                            marginTop: '0px',
                            paddingLeft: '4px',
                        }}
                    >
                        Your entry point to SUI!
                    </p>
                </div>
            </SLogo>
            <RightWrapper>
                <MobileToggle
                    style={{ marginLeft: '0' }}
                    isActive={id === 'dark'}
                    onToggle={setTheme}
                />
                {/* <MobileMenuIcon onClick={() => setMenuOpen((s) => !s)}>
                    <div />
                    <div />
                    <div />
                </MobileMenuIcon> */}
                {/* <MobilCircle> */}
                <MobileMenuIcon
                    onClick={() => setMenuOpen((s) => !s)}
                    open={menuOpen}
                >
                    <div />
                    <div />
                    <div />
                </MobileMenuIcon>
                {/* </MobilCircle> */}
            </RightWrapper>

            <Menu open={menuOpen}>
                <SLogo
                    style={{
                        marginLeft: '8px',
                        marginTop: '16px',
                        alignSelf: 'flex-start',
                    }}
                >
                    <a href="https://suigo.app">
                        <img
                            src={
                                id === 'dark'
                                    ? './images/suigo_white.png'
                                    : './images/suigo_black.png'
                            }
                            alt="Logo"
                            loading={'eager'}
                        />
                    </a>
                    <div>
                        <p
                            style={{
                                fontSize: '12px',
                                color: '#06bcef',
                                marginTop: '0px',
                                paddingLeft: '4px',
                            }}
                        >
                            Your entry point to SUI!
                        </p>
                    </div>
                </SLogo>

                <div>
                    <StyledLink
                        to="/"
                        isActive={pathname === '/'}
                        setMenuOpen={setMenuOpen}
                    >
                        Dashboard
                    </StyledLink>
                    <StyledLink
                        to="/validators"
                        isActive={pathname === '/validators'}
                        setMenuOpen={setMenuOpen}
                    >
                        Validators
                    </StyledLink>
                    <StyledLink
                        // style={{ color: 'grey' }}
                        // noHover
                        to="/node-checkpoint"
                        isActive={pathname === '/node-checkpoint'}
                        setMenuOpen={setMenuOpen}
                    >
                        Node Checkpoint
                    </StyledLink>
                    <StyledLink
                        to="/mystakes"
                        isActive={pathname === '/mystakes'}
                        setMenuOpen={setMenuOpen}
                    >
                        My Stakes
                    </StyledLink>
                    <DisabledLink>My Alerts</DisabledLink>
                    {/* <StyledLink
                        style={{ color: 'grey' }}
                        to={null}
                        // isActive={pathname === '/myalerts'}
                        setMenuOpen={setMenuOpen}
                    >
                        My Alerts
                    </StyledLink> */}
                    <StyledLink
                        // style={{ color: 'grey' }}
                        to="/faq"
                        isActive={pathname === '/faq'}
                        setMenuOpen={setMenuOpen}
                    >
                        F.A.Q.
                    </StyledLink>
                    {/* <Toggle isActive={id === 'dark'} onToggle={setTheme} /> */}
                </div>
                <div style={{ marginTop: '100px' }}>
                    <GateOmegaMobile />
                    <hr />
                    <p></p>
                    <Reserved>© 2021 GateOmega. All rights reserved.</Reserved>
                    <StyledLink
                        to="/privacypolicy"
                        isActive={pathname === '/privacypolicy'}
                        setMenuOpen={setMenuOpen}
                        style={{ fontSize: '10px' }}
                    >
                        Privacy Policy and Term of Use
                    </StyledLink>
                </div>
            </Menu>
        </HeaderWrapper>
    );
}
