import styled from 'styled-components';

export const Border = styled.div`
    position: relative;
    background-color: ${(p) => p.theme.widgetBackgroundColor};
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.widgetBorderColor};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 10px;
`;
