import styled from 'styled-components';
import { MdSearch } from 'react-icons/md';

export const SearchStil = styled.form`
    display: flex;
    align-items: center;
    border: 1px solid ${(p) => p.theme.searchBorderColor};
    border-radius: 8px;
    padding: 4px;
    justify-content: space-between;
    background-color: ${(p) => p.theme.searchBackground};
    input {
        padding: 8px 12px;
        flex: 1;
        font-size: 16px;
        color: ${(p) => p.theme.inputColor};
        border: none;
        outline: none;
        background-color: transparent;
        &::placeholder {
            color: ${(p) => p.theme.placeholderColor};
        }
    }
`;
export const ModalSearchIcon = styled(MdSearch)`
    font-size: 24px;
    color: ${(p) => p.theme.searchBorderColor};
    display: flex;
    align-items: center;
    margin-right: 8px;
`;
export const SearchButton = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
`;
