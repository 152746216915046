import styled from 'styled-components';
import { Border } from './border';

export const Stats = styled(Border)`
    flex: 1 1 0%;
    gap: 16px;
    padding: 18px 25px;
    justify-content: center;
    background-color: ${(p) => p.theme.widgetBackgroundColor};
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding-bottom: 5px;
    border-bottom: 0.1px solid ${(p) => p.theme.widgetBorderColor};
    &:nth-child(6) {
        border: none;
        margin-bottom: -14px;
        /* padding-top: 0px; */
    }
`;

export const LeftS = styled.div`
    display: flex;
    align-items: center;
    gap: 3px;
    flex: 1;
`;

export const RightS = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    /* font-size: 1.2rem; */
`;

export const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
`;

export const PercentageText = styled.span`
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
`;

export const ProgressBar = styled.progress`
    width: 100%;
    height: 10px;
    margin-top: 4px;
    /* accent-color: #54c9a8; */

    &[value] {
        --color: #54c9a8; /* the progress color */
        --background: #d8d7d7; /* the background color */

        border: none; /* Firefox add a default border */
        /* width: 200px; */
        /* margin: 0 10px; */
        border-radius: 10em;
        background: var(--background);
    }
    &[value]::-moz-progress-bar {
        border-radius: 10em;
        background: var(--color);
    }
    &[value]::-webkit-progress-bar {
        border-radius: 10em;
        background: var(--background);
    }
    &[value]::-webkit-progress-value {
        border-radius: 10em;
        background: var(--color);
    }
`;
