import React, { useState, useEffect, useRef } from 'react';
import ReactEcharts from 'echarts-for-react';
import { loadTotalStakeData } from '../../scriptsApi/totalStakeApi';
import * as echarts from 'echarts/core';

const MyChart = () => {
    const [options, setOptions] = useState({});
    const [stakeData, setStakeData] = useState([]);
    const chartRef = useRef(null);

    const fetchTotalStakeStats = async () => {
        try {
            let data = await (window.__TOTAL_STAKE_DATA_LOADER_CACHE__ ||
                loadTotalStakeData());
            // Transform the data to the expected format
            let transformedData = data.data.map((item) => {
                return [
                    new Date(item.date_time).toISOString().split('T')[0], // x value as date without time
                    Number(item.total_stake / 1.0e9).toFixed(0), // y value with 9 digits dropped and rounded
                ];
            });
            setStakeData(transformedData);
        } catch (error) {
            console.error('Error fetching Stake stats:', error);
        }
    };

    function formatValue(value) {
        if (Math.abs(value) >= 1.0e9) {
            return (value / 1.0e9).toFixed(0) + 'b';
        } else if (Math.abs(value) >= 1.0e6) {
            return (value / 1.0e6).toFixed(0) + 'm';
        } else if (Math.abs(value) >= 1.0e3) {
            return (value / 1.0e3).toFixed(0) + 'k';
        } else {
            return value.toString();
        }
    }
    // handle the restore event
    const handleRestore = () => {
        if (chartRef.current) {
            chartRef.current.getEchartsInstance().setOption(options, true);
        }
    };
    useEffect(() => {
        fetchTotalStakeStats();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.getEchartsInstance().resize();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setOptions({
            // tooltip: {
            //     trigger: 'axis',
            //     position: function (pt) {
            //         return [pt[2], '10%'];
            //     },
            // },
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[2], '10%'];
                },
                formatter: function (params) {
                    const date = new Date(params[0].axisValue);
                    const dateString = `${date.getFullYear()}-${
                        date.getMonth() + 1
                    }-${date.getDate()}`;
                    const seriesName = params[0].seriesName;
                    const value = params[0].data[1];
                    const formattedValue = Number(value).toLocaleString();
                    return `Date: ${dateString} <br/>
                    <div style="display: flex; align-items: center; gap:4px " > <span style="background-color: #09dfbc; border-radius: 50%; width: 10px; height: 10px; display: inline-block;"> </span> 
                           ${seriesName}: <strong>${formattedValue} SUI</strong> </div>`;
                },
            },

            title: {
                left: 'center',
                text: 'Total Stake',
            },
            toolbox: {
                feature: {
                    dataZoom: {
                        show: false,
                        yAxisIndex: 'none',
                    },
                    restore: {},
                    saveAsImage: {},
                },
            },
            xAxis: {
                type: 'time',
                boundaryGap: false,
            },
            // grid: {
            //     left: '15%',
            // },

            yAxis: {
                type: 'value',
                boundaryGap: [0, '100%'],
                axisLabel: {
                    formatter: function (value) {
                        return formatValue(value);
                    },
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(77, 84, 102, 0.1)',
                    },
                }, // add this line to hide grid lines
            },

            dataZoom: [
                {
                    type: 'inside',
                    start: 0,
                    end: 100,
                },
                {
                    start: 0,
                    end: 100,
                },
            ],
            series: [
                {
                    name: 'Total Stake',
                    type: 'line',
                    // color: '#1b38b7b6',
                    smooth: true,
                    symbol: 'none',
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 1,
                                color: 'rgba(84, 112, 198, 0.01)', // start color with full opacity
                            },
                            {
                                offset: 0.7,
                                color: 'rgba(84, 112, 198, 0.01)', // start color with full opacity
                            },
                            {
                                offset: 0.3,
                                color: 'rgba(7, 19, 55, 0.2)', // start color with full opacity
                            },
                            {
                                offset: 0,
                                color: '#071337', // end color with zero opacity
                            },
                        ]),
                    },
                    data: stakeData,
                },
            ],
        });
        // console.log(stakeData);
    }, [stakeData]);

    return (
        <ReactEcharts
            ref={chartRef}
            option={options}
            style={{ minHeight: '360px', height: '100%', width: '100%' }}
            onEvents={{ restore: handleRestore }}
        />
    );
};

export default MyChart;
