import React from 'react';
import {
    ModalSearchIcon,
    SearchButton,
    SearchStil,
} from '../../styles/globalFilterStyle';

export const GlobalFilter = ({ filter, setFilter }) => {
    return (
        <SearchStil>
            <input
                value={filter || ''}
                onChange={(e) => setFilter(e.target.value)}
                placeholder="Search for validator"
            />
            <SearchButton type="submit">
                <ModalSearchIcon />
            </SearchButton>
        </SearchStil>
    );
};
