import React, { useState, useEffect } from 'react';
import { PageLayout } from '../layout/PageLayout';
import { useParams } from 'react-router-dom';
import TableWithReactQueryProvider from '../tables/ValidatorTable';
import { loadSingleValidator } from '../../scriptsApi/singleValidatorApi.js';
import { loadValidatorStatusData } from '../../scriptsApi/statusApi';
import ValidatorStake from './ValidatorStake';
import ValidatorInfo from './ValidatorInfo';
import ValidatorHealth from './ValidatorHealth';
import ValidatorApy from './ValidatorApy';
import SingleStakeChart from '../charts/SingleStakeChart';
import {
    Chart,
    MainWrapper,
    ValInfoWrapper,
    Wrapper,
    ChartWrapper,
} from '../../styles/singleValidatorStyle';
import Breadcrumbs from '../Breadcrumbs';

export default function SingleValidator() {
    const { name } = useParams();
    const [validatorData, setValidatorData] = useState(null);
    const [validatorStatusData, setValidatorStatusData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchValidatorData = async () => {
            const data = await loadSingleValidator(name);
            setValidatorData(data);
        };

        const fetchValidatorStatusData = async () => {
            const data = await loadValidatorStatusData(name);
            setValidatorStatusData(data);
        };

        setIsLoading(true);
        Promise.all([fetchValidatorData(), fetchValidatorStatusData()])
            .then(() => setIsLoading(false))
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    }, [name]);

    // if (isLoading) {
    //     return <div>Loading...</div>;
    // }

    return (
        <PageLayout>
            {/* <Breadcrumbs /> */}
            <ValidatorInfo validatorName={name} validatorData={validatorData} />
            <MainWrapper>
                <ValInfoWrapper>
                    <ValidatorStake
                        validatorName={name}
                        validatorData={validatorData}
                    />
                    <ValidatorHealth
                        validatorName={name}
                        validatorData={validatorStatusData}
                    />
                    <ValidatorApy
                        validatorName={name}
                        validatorData={validatorData}
                    />
                </ValInfoWrapper>
                <Chart>
                    <ChartWrapper>
                        <SingleStakeChart validatorData={validatorData} />
                    </ChartWrapper>
                </Chart>
            </MainWrapper>
            <TableWithReactQueryProvider />
        </PageLayout>
    );
}
