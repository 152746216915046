import axios from 'axios';

// if (!window.__DELEGATOR_DATA_LOADER_CACHE__) {
//     window.__DELEGATOR_DATA_LOADER_CACHE__ = {};
// }

export const loadSingleDelegator = async (suiAddress) => {
    try {
        // const cachedDelegatorStake = window.__DELEGATOR_DATA_LOADER_CACHE__[suiAddress];

        // if (cachedDelegatorStake) {
        //     // Eğer validator zaten cachede ise, cacheden döndür
        //     return cachedDelegatorStake;
        // } else {
        // Eğer validator cachede yoksa, API'dan çek
        const response = await axios.get(
            `https://suigo.app/api/v7/stake_info?sui_address=${suiAddress}`
            // `http://localhost:7007/api/v7/stake_info?sui_address=${suiAddress}`
        );
        const delegatorData = response.data;

        // Çekilen veriyi cache'e ekleyin
        // window.__DELEGATOR_DATA_LOADER_CACHE__[suiAddress] = delegatorData;
        // console.log('dd', delegatorData);
        return delegatorData;
        // }
    } catch (error) {
        console.error('Error fetching validator:', error);
    }
};

// export const loadSingleDelegator = async (suiAddress) => {
//     try {
//         const cachedDelegatorStake =
//             window.__DELEGATOR_DATA_LOADER_CACHE__[suiAddress];

//         if (cachedDelegatorStake) {
//             // Eğer validator zaten cachede ise, cacheden döndür
//             return cachedDelegatorStake;
//         } else {
//             // Eğer validator cachede yoksa, API'dan çek
//             const response = await axios.get(
//                 `http://49.12.186.185:7007/stake_info?sui_address=${suiAddress}`
//             );
//             const delegatorData = response.data;

//             // Çekilen veriyi cache'e ekleyin
//             window.__DELEGATOR_DATA_LOADER_CACHE__[suiAddress] = delegatorData;
//             console.log('dd', delegatorData);
//             return delegatorData;
//         }
//     } catch (error) {
//         console.error('Error fetching validator:', error);
//     }
// };
