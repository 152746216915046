import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
// import ToggleAlert from '../toggle/ToogleAlert';
import NewToggleAlert from '../toggle/NewToggleAlert';
import { MdClose, MdSearch } from 'react-icons/md';
import { generateUUID } from '../../scriptsApi/modalUuid';
import TelegramModal from './TelegramModal';

const TogWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const Background = styled.div`
    width: 80%;
    height: 80%;
    background: rgba(0, 0, 0, 0);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
`;

const ModalWrapper = styled.div`
    min-width: 600px;
    width: 700px;
    /* height: 350px; */
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    background: #515f5c;
    color: #000;
    display: flex;
    position: relative;
    z-index: 10;
    border-radius: 10px;
    border: 0.5px solid ${(p) => p.theme.toggleColor};
    padding: 16px;
`;

const Search = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #688881;
    border-radius: 8px;
    padding: 4px;
    justify-content: space-between;
    background-color: #22554a;

    input {
        padding: 8px 12px;
        flex: 1;
        font-size: 16px;
        color: #ffffff96;
        border: none;
        outline: none;
        background-color: transparent;
        &::placeholder {
            color: #ffffff3e;
        }
    }
`;

const Email = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #688881;
    border-radius: 8px;
    /* padding: 4px; */
    /* justify-content: space-between; */
    background-color: transparent;
    span {
        background-color: #c6cccb;
        display: flex;
        align-items: center;
        width: 80px;
        margin: 0;
        height: 100%;
        border-radius: 8px 0 0 8px;
        color: #22554a;
        padding-left: 16px;
    }
    input {
        padding: 8px 12px;
        flex: 1;
        font-size: 16px;
        color: #ffffff96;
        border: none;
        outline: none;
        background-color: transparent;
        &::placeholder {
            color: #ffffff3e;
        }
    }
`;

const AlertButton = styled.button`
    padding: 10px 24px;
    background: #141414;
    color: #fff;
    border: 0.2px solid #141414;
    width: 20%;
    border-radius: 8px;
    cursor: pointer;
    &:hover,
    :active {
        background: #22554a;
        color: #dadada;
        border: 0.2px solid #141414;
    }
`;

const ModalContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    /* align-content: flex-start; */
    align-items: left;
    line-height: 1.5;
    color: #e2e2e2;
    gap: 8px;
    margin-right: 16px;

    p {
        /* border-radius: 8px;
        border: 1px solid #688881; */
        margin: 0;
        padding: 0 8px;
        font-size: 12px;
    }
`;

const CloseModalButton = styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    padding: 0;
    z-index: 10;
`;
const ModalSearchIcon = styled(MdSearch)`
    font-size: 24px;
    color: #ffffff5d;
    display: flex;
    align-items: center;
    margin-right: 8px;
`;

export const AlertModalNew = ({ showModal, setShowModal }) => {
    const [alertUUID, setAlertUUID] = useState(null);
    const [validators, setValidators] = useState([]);
    const [search, setSearch] = useState('');
    const [uptime, setUptime] = useState(false);
    const [commission, setCommission] = useState(false);
    const [stake, setStake] = useState(false);
    const [showTelegramModal, setShowTelegramModal] = useState(false);

    const handleSubmit = () => {
        startAlertProcess();

        setShowModal(false);
        setShowTelegramModal(true);
    };

    const handleUptimeToggle = () => {
        setUptime(!uptime);
    };

    const handleToggleCommission = () => {
        setCommission(!commission);
    };

    const handleToggleStake = () => {
        setStake(!stake);
    };

    const modalRef = useRef();

    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            setShowModal(false);
            setShowTelegramModal(false);
            setCommission(false);
            setUptime(false);
        }
    };

    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    const startAlertProcess = () => {
        const newUUID = generateUUID();
        setAlertUUID(newUUID);
        console.log('Generated UUID:', newUUID);
    };
    return (
        <>
            {showModal && (
                <Background onClick={closeModal} ref={modalRef}>
                    <ModalWrapper $showModal={showModal}>
                        <ModalContent>
                            <p>
                                Please choose the alerts you'd like to receive
                            </p>
                            <Search>
                                <input
                                    type="text"
                                    value={search}
                                    onChange={handleSearch}
                                    list="validators"
                                    placeholder="Search for a validator..."
                                ></input>
                                {/* <datalist id="validators">
                                    {validators.map((validator, index) => (
                                        <option
                                            key={index}
                                            value={validator.name}
                                        />
                                    ))}
                                </datalist> */}
                                <ModalSearchIcon />
                            </Search>
                            <TogWrapper>
                                <NewToggleAlert
                                    isAlertActive={uptime}
                                    onAlertToggle={handleUptimeToggle}
                                />
                                <p>Get Uptime & Status Alert</p>
                            </TogWrapper>
                            <TogWrapper>
                                <NewToggleAlert
                                    isAlertActive={commission}
                                    onAlertToggle={handleToggleCommission}
                                />
                                <p>Get Commission Change Alert</p>
                            </TogWrapper>
                            {/* <TogWrapper>
                                <NewToggleAlert
                                    isAlertActive={stake}
                                    onAlertToggle={handleToggleStake}
                                />
                                <p>Get Stake Change Alert</p>
                            </TogWrapper> */}
                            <AlertButton onClick={handleSubmit}>
                                Submit
                            </AlertButton>
                        </ModalContent>
                        <CloseModalButton
                            aria-label="Close modal"
                            onClick={() => {
                                setShowModal(false);
                                setShowTelegramModal(false);
                                setCommission(false);
                                setUptime(false);
                            }}
                        />
                    </ModalWrapper>
                </Background>
            )}
            {showTelegramModal && (
                <Background onClick={closeModal} ref={modalRef}>
                    <ModalWrapper>
                        <TelegramModal
                            showTelegramModal={showTelegramModal}
                            setShowTelegramModal={setShowTelegramModal}
                            setCommission={setCommission}
                            setUptime={setUptime}
                        />
                    </ModalWrapper>
                </Background>
            )}
        </>
    );
};
