import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import TpsChart from '../charts/TpsChart';
import SuiPriceChart from '../charts/SuiPriceChart';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { loadNetworkStats } from '../../scriptsApi/networkStatsApi';
import { Spinner } from '../../styles/spinner';
import {
    NetworkLeft,
    ProgressBarContainer,
    PercentageText,
    ProgressBar,
} from '../../styles/networkStatsLeftStyle';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const CustomTooltip = styled(ReactTooltip)`
    background: ${(p) => p.theme.bodyFontColor} !important;
    font-size: 11px !important;
    font-weight: 500 !important;
    overflow-wrap: break-word;
    max-width: 300px !important;
    padding: 4px 4px !important;
    z-index: 15;
    color: ${(p) => p.theme.bodyBackgroundColor} !important;
`;

const NetworkStatsLeft = () => {
    const [networkStats, setNetworkStats] = useState(null);

    const fetchNetworkStats = async () => {
        try {
            const data = await loadNetworkStats();
            setNetworkStats(data);
            window.__DATA_LOADER_CACHE__ = data;
        } catch (error) {
            console.error('Error fetching network stats:', error);
        }
    };

    const renderData = (key, format) => {
        if (networkStats && networkStats[key]) {
            let value = networkStats[key];
            if (key === 'averageTps30days') {
                value = Number(value).toFixed(0);
            }
            const formattedValue =
                format === 'toLocaleString'
                    ? Number(value).toLocaleString()
                    : value;
            return <span>{formattedValue}</span>;
        }
        return (
            <div>
                <Spinner />
            </div>
        );
    };

    useEffect(() => {
        fetchNetworkStats();

        const intervalId = setInterval(() => {
            fetchNetworkStats();
        }, 60000); // her 60 saniyede data cekimi

        // clear interval
        return () => clearInterval(intervalId);
    }, []);

    return (
        <>
            <NetworkLeft>
                <h1>Current Epoch</h1>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <span>{renderData('epoch')}</span>
                    <span>
                        {networkStats && (
                            <ProgressBarContainer>
                                <PercentageText>
                                    <span>
                                        {' '}
                                        % {networkStats.epochRemainingPerc}{' '}
                                    </span>

                                    {/* ETA: {''} */}
                                    <span style={{ fontSize: 12 }}>
                                        {' '}
                                        ETA:{' '}
                                        {networkStats.epochRemainingTime
                                            .split(':')
                                            .slice(0, 2)
                                            .join(':')}{' '}
                                        hrs
                                    </span>
                                </PercentageText>
                                <ProgressBar
                                    value={networkStats.epochRemainingPerc}
                                    max="100"
                                    color="green"
                                />
                            </ProgressBarContainer>
                        )}
                    </span>
                </div>
            </NetworkLeft>
            <NetworkLeft>
                <h1>
                    Current TBPS / Average TBPS{' '}
                    <span
                        style={{
                            fontSize: 12,
                            alignItems: 'center',
                            color: 'grey',
                        }}
                    >
                        (24h){' '}
                        <InfoOutlinedIcon
                            data-tooltip-id="my-tooltip-7"
                            sx={{ fontSize: 12, color: 'grey' }}
                        />
                        <CustomTooltip
                            id="my-tooltip-7"
                            place="bottom"
                            content="The number of TX blocks within one second in the Sui network."
                        />
                    </span>{' '}
                </h1>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{renderData('currentTps')}</span>
                        <span style={{ margin: '0 5px' }}> / </span>
                        <span>{renderData('averageTps30days')} </span>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ fontSize: '12px', paddingRight: '5px' }}>
                            24h
                        </span>
                        <TpsChart />
                    </div>
                </div>
            </NetworkLeft>
            <NetworkLeft>
                <h1>
                    <img
                        src="./images/sui_logo.png"
                        width="10rem"
                        alt="Logo"
                        loading={'eager'}
                    />{' '}
                    Sui Price
                </h1>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '4px' }}> $ </span>
                        <span>{renderData('suiPrice')}</span>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ paddingRight: '5px', fontSize: '12px' }}>
                            24h
                        </span>
                        <SuiPriceChart />
                    </div>
                </div>
            </NetworkLeft>
        </>
    );
};

export default NetworkStatsLeft;
