import React, { useEffect, useState } from 'react';
import { BarChart, Bar, ResponsiveContainer } from 'recharts';

const SingleHealthChart = ({ validatorData }) => {
    // console.log(validatorData);
    const [processedData, setProcessedData] = useState([]);

    const processData = (data) => {
        const processedData = data.map((item) => {
            const { date_time } = item;
            const activityLevel = getActivityLevel(data, date_time);
            return {
                date_time: date_time,
                activityLevel: activityLevel,
            };
        });
        return processedData;
    };

    const getActivityLevel = (data, dateTime) => {
        const timePeriod = 15 * 60 * 1000; // 15 dakika (milisaniye cinsinden)
        const index = data.findIndex((item) => item.date_time === dateTime);

        if (
            index === data.length - 1 ||
            new Date(data[index + 1].date_time) - new Date(dateTime) >
                timePeriod
        ) {
            return 0;
        }

        return 100;
    };

    useEffect(() => {
        if (validatorData) {
            const processedData = processData(validatorData);
            setProcessedData(processedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validatorData]);

    return (
        <div style={{ width: '95%', height: 50 }}>
            <ResponsiveContainer>
                {processedData && (
                    <BarChart width={40} height={30} data={processedData}>
                        {/* {console.log('BarChart rendering')} */}
                        <Bar dataKey="activityLevel" fill="#14c77f" />
                    </BarChart>
                )}
            </ResponsiveContainer>
        </div>
    );
};

export default SingleHealthChart;
