import axios from 'axios';

export const loadLastStakesApy = async () => {
    try {
        const response = await axios.get(
            'https://suigo.app/api/v4/totalStakeApy'
        );
        const sortedData = response.data.data.sort(
            (a, b) => new Date(b.date_time) - new Date(a.date_time)
        );

        return {
            data: sortedData, // Tüm data
            latest: sortedData[0], // son data
            lastbefore: sortedData[1],
        };
        // sortedData[0]; // This will return the latest data
    } catch (error) {
        console.error('Error fetching loadTotalStakeApy:', error);
    }
};

export const loadAvgApyData = async () => {
    try {
        const response = await axios.get(
            'https://suigo.app/api/v4/totalStakeApy'
        );

        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching loadTotalStakeData:', error);
        throw error; // Hata durumunda bir hata fırlatır
    }
};

export const loadTotalStakeData = async () => {
    try {
        const response = await axios.get(
            'https://suigo.app/api/v4/totalStakeApy'
        );

        // console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching loadTotalStakeData:', error);
        throw error; // Hata durumunda bir hata fırlatır
    }
};
