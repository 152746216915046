import React from 'react';
import styled from 'styled-components';
import { IconContext } from 'react-icons';
// import { Social } from '../../styles/gateOmegaStyle';
import { FaMedium, FaTwitter, FaTelegramPlane } from 'react-icons/fa';
import { TfiWorld } from 'react-icons/tfi';

const Reserved = styled.div`
    font-size: 8px;
`;

const Social = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 2rem auto;

    a {
        color: ${(p) => (p.isActive ? '#01c2d5' : p.theme.bodyFontColor)};
    }
    a:hover {
        color: #01c2d5;
    }

    div {
        hr {
            border-top: 1px solid red;
        }
    }
`;

{
    /* <IconContext.Provider value={{ color: 'blue', className: 'global-class-name' }}>
    <div>
        <FaFolder />
    </div>
</IconContext.Provider>; */
}
const GateOmegaMobile = () => {
    return (
        <>
            {/* <div>
                {' '}
                <hr
                    style={{ border: '0.1px solid grey', marginRight: '80px' }}
                />{' '}
            </div> */}
            <Social>
                <a
                    href="https://gateomega.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IconContext.Provider
                        value={{
                            size: '2rem',
                        }}
                    >
                        {' '}
                        <TfiWorld style={{ width: '32px' }} />
                    </IconContext.Provider>
                </a>
                <a
                    href="https://twitter.com/gateomega"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IconContext.Provider
                        value={{
                            size: '2rem',
                        }}
                    >
                        <FaTwitter />{' '}
                    </IconContext.Provider>
                </a>
                <a
                    href="https://t.me/gateomega"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IconContext.Provider
                        value={{
                            size: '2rem',
                        }}
                    >
                        <FaTelegramPlane />{' '}
                    </IconContext.Provider>
                </a>
                <a
                    href="https://gateomega.medium.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <IconContext.Provider
                        value={{
                            size: '2rem',
                        }}
                    >
                        <FaMedium />{' '}
                    </IconContext.Provider>
                </a>
            </Social>
        </>
    );
};

export default GateOmegaMobile;
