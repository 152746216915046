import { PageLayout } from '../components/layout/PageLayout';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { items } from '../data/faqContent';
// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemButton,
//     AccordionItemPanel,
// } from 'react-accessible-accordion';
import Accordion from '../components/accordion/Accordion';
import { Helmet } from 'react-helmet-async';
export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    @media (min-width: 80rem) {
        flex-direction: row;
    }
`;

const FaqTitle = styled.p`
    /* font-family: monospace; */
    font-size: 16px;
`;

// const CustomAccordionItemButton = styled(AccordionItemButton)`
//     color: red;
//     height: 60px;
//     width: 60px;
// `;

// export default function Faq() {
//     return (
//         <PageLayout>
//             <Accordion
//                 items={[
//                     { title: 'Title 1', content: 'Content 1' },
//                     { title: 'Title 2', content: 'Content 2' },
//                     // more items...
//                 ]}
//             />
//         </PageLayout>
//     );
// }
export default function Faq() {
    return (
        <PageLayout>
            {/* <FaqTitle>FAQ</FaqTitle> */}
            <Helmet>
                <title>SuiGo - FAQ</title>
                <meta property="og:url" content="https://suigo.app/faq" />
            </Helmet>
            <Accordion items={items} />
        </PageLayout>
    );
}

// <Accordion>
// {items.map((item) => (
//     <AccordionItem key={item.uuid}>
//         <AccordionItemHeading>
//             <CustomAccordionItemButton>
//                 {/* {item.heading} */}deneme
//             </CustomAccordionItemButton>
//         </AccordionItemHeading>
//         <AccordionItemPanel>
//             {item.content}
//         </AccordionItemPanel>
//     </AccordionItem>
// ))}
// </Accordion>

// const CustomAccordion = styled(Accordion)`
//     background-color: blue;
// `;

// const CustomAccordionItem = styled(AccordionItem)`
//     background-color: red;
// `;

// const CustomAccordionItemHeading = styled(AccordionItemHeading)`
//     background-color: green;
// `;
// const CustomAccordionItemButton = styled(AccordionItemButton)`
//     background-color: yellow;
// `;
// const CustomAccordionItemPanel = styled(AccordionItemPanel)``;
