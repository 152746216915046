import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Validators from './pages/Validators';
import MyStakes from './pages/MyStakes';
import MyAlerts from './pages/MyAlerts';
import NodeMonitoring from './pages/NodeMonitoring';
import PrivacyPolicy from './pages/Privacy';
import Faq from './pages/Faq';
import './scriptsApi/preLoad.js';
import SingleValidator from './components/validators/SingleValidator';
import LightTheme from './styles/light';
import DarkTheme from './styles/dark';
import { GlobalStyle } from './styles/globalStyles';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';

// import PrivacyPolicy from './pages/Privacy';
ReactGA.initialize('G-99YSDSXBGQ');

function InnerComponent() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }, [location]);
}

function App() {
    const getThemeById = (id) => (id === 'light' ? LightTheme : DarkTheme);

    const [theme, setTheme] = useState(() => {
        const localThemeId = window.localStorage.getItem('themeId');
        return localThemeId ? getThemeById(localThemeId) : LightTheme;
    });

    useEffect(() => {
        window.localStorage.setItem('themeId', theme.id);
    }, [theme]);

    const toggleTheme = () => {
        setTheme((currentTheme) => {
            const newTheme =
                currentTheme.id === 'light' ? DarkTheme : LightTheme;
            return newTheme;
        });
    };

    return (
        <ThemeProvider
            theme={{
                ...theme,
                setTheme: toggleTheme,
            }}
        >
            <HelmetProvider>
                <Helmet></Helmet>
                <GlobalStyle />
                <BrowserRouter>
                    <InnerComponent />
                    <Routes>
                        <Route path="/" element={<Dashboard />}></Route>
                        <Route
                            path="/validators"
                            element={<Validators />}
                        ></Route>
                        <Route path="/mystakes" element={<MyStakes />}></Route>
                        <Route path="/myalerts" element={<MyAlerts />}></Route>
                        <Route path="/faq" element={<Faq />}></Route>
                        <Route
                            path="/privacypolicy"
                            element={<PrivacyPolicy />}
                        ></Route>
                        <Route
                            path="/node-checkpoint"
                            element={<NodeMonitoring />}
                        ></Route>
                        <Route
                            path=":name"
                            element={<SingleValidator />}
                        ></Route>
                    </Routes>
                </BrowserRouter>
            </HelmetProvider>
        </ThemeProvider>
    );
}
export default App;
