import axios from 'axios';

export async function loadEpochStats() {
    try {
        const response = await axios.get('https://suigo.app/api/v2/epoch');
        const data = response.data;
        // Cache'e data'yı at
        window.__EPOCH_DATA_LOADER_CACHE__ = data;

        return data;
    } catch (error) {
        console.error('Error fetching epoch stats:', error);
    }
}
