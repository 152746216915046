import styled from 'styled-components';

export const SideNavbar = styled.div`
    width: 240px;
    display: none;
    height: 100vh;
    position: fixed;
    top: 0px;
    bottom: 0px;
    flex-direction: column;
    gap: 20px;
    padding: 24px;
    overflow-y: auto;

    @media (min-width: 64rem) {
        display: flex;
    }
`;

export const Navigation = styled.nav`
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 16px;
    user-select: none;
`;

export const SLogo = styled.div`
    img {
        height: 48px;
    }
`;
