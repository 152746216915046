import styled, { keyframes } from 'styled-components';
import { Border } from './border';

export const Wrapper = styled(Border)`
    @media screen and (min-width: 64rem) {
        max-width: calc(100vw - 32px);
    }

    width: 100%;
    max-width: calc(100vw - 32px);
    overflow-x: auto;
    font-size: 0.9rem;
    border-radius: 0px;

    table {
        table-layout: auto;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px;
    }

    thead {
        position: sticky;
        top: 0px;
        margin: 0px;
        color: ${(p) => p.theme.bodyFontColor};
        background-color: ${(p) => p.isActive && p.theme.selectColor};

        th {
            z-index: 1;

            background-color: ${(p) =>
                p.isActive ? '#01c2d5' : p.theme.selectColor} !important;
        }
    }

    tbody td {
        text-align: right;
    }

    tr {
        border-bottom: 0.5px solid ${(p) => p.theme.widgetBorderColor};

        > :first-child {
            @media screen and (max-width: 32rem) {
                display: none;
                border: 0.5px solid ${(p) => p.theme.widgetBorderColor};
            }
            /* background-color: white;
            position: sticky;
            left: 0px;
            z-index: 2; */
        }
        > :nth-child(2) {
            position: sticky;
            left: 0px;
            z-index: 2;
            background-color: ${(p) => p.theme.bodyBackgroundColor};
        }
    }
    /* thead th {
        text-align: left !important;
    } */

    th,
    td {
        padding: 0.6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: ${(p) => p.theme.bodyBackgroundColor};
        border: 0.7px solid ${(p) => p.theme.widgetBorderColor};
    }

    span {
        display: flex;
        /* -webkit-box-pack: end; */
        /* justify-content: flex-end; */
        -webkit-box-align: center;
        align-items: center;
        flex-wrap: nowrap;
        gap: 4px;
        font-weight: 500;
        position: relative;
        max-width: 120px;
        /* text-align: right; */
    }
`;
export const load = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
`;

// Styled loading bar
export const LoadingBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 100%;
    overflow: hidden;
    background-color: #ddd;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #2979ff;
        animation: ${load} 2s linear infinite;
    }
`;

export const pulseAnimation = keyframes`
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
`;

export const CircleGreen = styled.div`
    width: 10px;
    height: 10px;
    background-color: #70ed9d;
    border-radius: 50%;
    animation: ${pulseAnimation} 2s ease-in-out infinite;
`;
export const CircleRed = styled.div`
    width: 10px;
    height: 10px;
    background-color: #e54f31;
    border-radius: 50%;
    animation: ${pulseAnimation} 2s ease-in-out infinite;
`;
//preload data
// export const StyledSpan = styled.span`
//     display: flex;
//     -webkit-box-pack: start;
//     justify-content: ${(props) =>
//         props.alignRight ? 'flex-end' : 'flex-start'} !important;
//     -webkit-box-align: center;
//     align-items: center;
//     flex-wrap: nowrap;
//     gap: 4px;
//     font-weight: 500;
//     position: relative;
// `;

export const StyledSpan = styled(({ alignLeft, alignRight, ...rest }) => (
    <span {...rest} />
))`
    display: flex;
    -webkit-box-pack: start;
    justify-content: ${(props) =>
        props.alignRight ? 'flex-end' : 'flex-start'} !important;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;
    font-weight: 500;
    position: relative;
`;
