import { PageLayout } from '../components/layout/PageLayout';
import React, { useState } from 'react';
import styled from 'styled-components';
import { AlertModalNew } from '../components/alertmodal/AlertModalNew';
import { Helmet } from 'react-helmet-async';
const WrapContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100vh;
`;

const Button = styled.button`
    min-width: 100px;
    padding: 16px 32px;
    border-radius: 4px;
    border: none;
    background: #141414;
    color: #fff;
    font-size: 24px;
    cursor: pointer;
`;

export default function MyAlerts() {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal((showModal) => !showModal);
    };

    return (
        <PageLayout>
            <Helmet>
                {' '}
                <title>SuiGo - My Alerts</title>
                <meta property="og:url" content="https://suigo.app/myalerts" />
            </Helmet>
            <p>Delegator Stake Alert</p>
            <p>Commission Alert</p>
            <p>Status Alert</p>
            <p>Validator Stake Alert</p>

            <WrapContainer>
                {/* <Button onClick={openModal}>Add Alert</Button> */}
                <AlertModalNew
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            </WrapContainer>
        </PageLayout>
    );
}
