import axios from 'axios';

if (!window.__SINGLE_DATA_LOADER_CACHE__) {
    window.__SINGLE_DATA_LOADER_CACHE__ = {};
}
export const loadSingleValidator = async (validatorName) => {
    try {
        const cachedValidator =
            window.__SINGLE_DATA_LOADER_CACHE__[validatorName];

        if (cachedValidator) {
            // Eğer validator zaten cachede ise, cacheden döndür
            return cachedValidator;
        } else {
            // Eğer validator cachede yoksa, API'dan çek
            const response = await axios.get(
                `https://suigo.app/api/v1/validators?name=${validatorName}`
            );
            const validatorData = response.data;

            // Çekilen veriyi cache'e ekleyin
            window.__SINGLE_DATA_LOADER_CACHE__[validatorName] = validatorData;
            // console.log('dd', validatorData);
            return validatorData;
        }
    } catch (error) {
        console.error('Error fetching validator:', error);
    }
};

// export const loadSingleValidator = async (validatorName) => {
//     try {
//         const response = await axios.get(
//             `http://49.12.186.185:7001/validators?name=${validatorName}`
//         );
//         return response.data;
//     } catch (error) {
//         console.error('Error fetching validator:', error);
//     }
// };
