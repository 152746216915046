import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Border } from '../../styles/border';
import { loadLastStakesApy } from '../../scriptsApi/totalStakeApi';
import AvgApyCharts from '../charts/AvgApyChart';

const AllValidatorLeft = styled(Border)`
    flex: 1 1 0%;
    gap: 4px;
    padding: 18px 25px;
    -webkit-box-pack: center;
    justify-content: center;

    h1 {
        min-width: 0px;
        font-weight: 600;
        font-size: 1rem;
        color: #54c9a8;
    }

    p {
        margin: 4px 0px -6px;
        font-weight: 400;
        font-size: 1rem;
    }
`;
const AllValidatorStats = () => {
    const [apyStakeStats, setApyStakeStats] = useState(null);

    const fetchLastApyStake = async () => {
        try {
            const data = await (window.__LAST_STAPY_DATA_LOADER_CACHE__ ||
                loadLastStakesApy());
            setApyStakeStats(data);
        } catch (error) {
            console.error('Error fetching network stats:', error);
        }
    };

    useEffect(() => {
        fetchLastApyStake();
        const intervalId = setInterval(() => {
            fetchLastApyStake();
        }, 360000);
        // clear interval
        return () => clearInterval(intervalId);
    }, []);

    const renderData = (key) => {
        if (apyStakeStats && apyStakeStats.latest) {
            let value;
            if (key === 'average_apy') {
                value = parseFloat(apyStakeStats.latest.average_apy).toFixed(2);
            } else if (key === 'total_stake') {
                const stakeInBillion = (
                    apyStakeStats.latest.total_stake / 1000000000
                ).toFixed(0);
                value = Number(stakeInBillion).toLocaleString();
            }
            return <span>{value}</span>;
        }
        return <span>Loading...</span>;
    };

    const calculateStakeChange = () => {
        if (apyStakeStats && apyStakeStats.latest && apyStakeStats.lastbefore) {
            const lastStake = apyStakeStats.latest.total_stake;
            const lastBeforeStake = apyStakeStats.lastbefore.total_stake;
            const stakeChange =
                ((lastStake - lastBeforeStake) / lastStake) * 100;
            return Number(stakeChange.toFixed(2));
        }
        return 'Loading...';
    };

    return (
        <>
            <AllValidatorLeft>
                <h1>Total Stake</h1>
                <span style={{ fontSize: '1.2rem' }}>
                    {renderData('total_stake')} SUI
                </span>
            </AllValidatorLeft>
            <AllValidatorLeft>
                <h1>
                    Stake Change{' '}
                    <span
                        style={{
                            fontSize: '13px',
                            color: 'darkgrey',
                        }}
                    >
                        (24h)
                    </span>
                </h1>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.2rem',
                        color:
                            calculateStakeChange() > 0 ? '#31c763' : '#cb3a3c',
                    }}
                >
                    <span>{calculateStakeChange()} %</span>
                </div>
            </AllValidatorLeft>
            <AllValidatorLeft>
                <h1>Average Apy</h1>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <span style={{ fontSize: '1.2rem' }}>
                        {renderData('average_apy')} %
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ paddingRight: '5px', fontSize: '12px' }}>
                            30d
                        </span>
                        <AvgApyCharts />
                    </div>
                </div>
            </AllValidatorLeft>
        </>
    );
};

export default AllValidatorStats;
