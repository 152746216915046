import React from 'react';
import styled from 'styled-components';
import { Border } from '../../styles/border';
import SingleHealthChart from '../charts/SingleHealthChart';

const ValidatorLeft = styled(Border)`
    flex: 1 1 0%;
    gap: 4px;
    padding: 18px 25px;
    -webkit-box-pack: center;
    justify-content: center;

    h1 {
        min-width: 0px;
        font-weight: 600;
        font-size: 1rem;
        color: #54c9a8;
    }

    p {
        margin: 4px 0px -6px;
        font-weight: 400;
        font-size: 1rem;
    }
`;
const ValidatorHealth = ({ validatorName, validatorData }) => {
    return (
        <>
            <ValidatorLeft>
                <h1>Status</h1>
                <SingleHealthChart validatorData={validatorData} />
            </ValidatorLeft>
        </>
    );
};

export default ValidatorHealth;
