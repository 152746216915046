import styled from 'styled-components';
import { Border } from './border';

export const Chart = styled(Border)`
    flex: 1 1 0%;
    gap: 4px;
    padding: 16px 8px;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: 400px;
    width: 100%;
    height: 100%;
`;

//
// export const Wrapper = styled.div`
//     display: grid;
//     grid-template-columns: 1fr;
//     border-radius: 12px;
//     gap: 16px;
//     position: relative;
//     isolation: isolate;

//     @media screen and (mind-width: 80rem) {
//         grid-template-columns: auto 1fr;
//     }
// `;

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;

    @media (min-width: 80rem) {
        flex-direction: row;
    }
`;

export const ValInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 0%;

    @media (min-width: 80rem) {
        max-width: 400px;
    }
`;

export const ChartWrapper = styled.div`
    flex: 1;
    min-width: 0; // Flex item'ın container'ın boyutunu aşmasını önler
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 20px;
    /* background-color: white; */
    border: 1px solid rgba(43, 43, 43, 0.035);
    border-radius: 12px;
`;
