import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Link as ReactRouterDomLink, useLocation } from 'react-router-dom';
import { Toggle } from '../toggle/Toggle';
import GateOmegaInfo from '../gateomega/GateOmegaInfo';
import { SideNavbar, SLogo, Navigation } from '../../styles/sidebarStyle';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const Link = ({ isActive, children, ...props }) => {
    return <ReactRouterDomLink {...props}>{children}</ReactRouterDomLink>;
};

const CustomTooltip = styled(ReactTooltip)`
    background: ${(p) => p.theme.bodyFontColor} !important;
    font-size: 11px !important;
    overflow-wrap: break-word;
    max-width: 300px;
    padding: 4px 4px !important;
    color: ${(p) => p.theme.bodyBackgroundColor} !important;
    z-index: 15;
`;

const StyledLink = styled(({ noHover, ...rest }) => <Link {...rest} />)`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    text-align: start;
    margin: -6px 0px -6px -6px;
    padding: 6px;
    border-radius: 6px;
    text-decoration: none;
    font-weight: ${(p) => (p.isActive ? '600' : 'normal')};
    color: ${(p) => (p.isActive ? '#01c2d5' : p.theme.bodyFontColor)};
    background-color: ${(p) => p.isActive && p.theme.selectColor};
    &:hover {
        background-color: ${(p) => !p.noHover && p.theme.selectColor};
    }
`;

// const StyledLink = styled(Link)`
//     display: flex;
//     -webkit-box-align: center;
//     align-items: center;
//     gap: 12px;
//     cursor: pointer;
//     text-align: start;
//     margin: -6px 0px -6px -6px;
//     padding: 6px;
//     border-radius: 6px;
//     text-decoration: none;
//     /* color: ${(p) => p.theme.bodyFontColor}; */
//     font-weight: ${(p) => (p.isActive ? '600' : 'normal')};
//     color: ${(p) => (p.isActive ? '#01c2d5' : p.theme.bodyFontColor)};
//     background-color: ${(p) => p.isActive && p.theme.selectColor};
//     /* background-color: #c9c9c9ba */
//     &:hover {
//         background-color: ${(p) => p.theme.selectColor};
//     }
// `;

export function Sidebar({ ...props }) {
    const { pathname } = useLocation();
    const { id, setTheme } = useContext(ThemeContext);

    return (
        <SideNavbar>
            <SLogo>
                <a href="https://suigo.app">
                    <img
                        src={
                            id === 'dark'
                                ? './images/suigo_white.png'
                                : './images/suigo_black.png'
                        }
                        alt="Logo"
                        loading={'eager'}
                    />
                </a>
                <div>
                    <p style={{ color: '#06bcef', marginTop: '0px' }}>
                        Your entry point to SUI!
                    </p>
                </div>
            </SLogo>

            <Navigation>
                <StyledLink to="/" isActive={pathname === '/'}>
                    Dashboard
                </StyledLink>
                <StyledLink
                    to="/validators"
                    isActive={pathname === '/validators'}
                >
                    Validators
                </StyledLink>
                <StyledLink
                    // style={{ color: 'grey' }}
                    noHover
                    to="/node-checkpoint"
                    isActive={pathname === '/node-checkpoint'}
                >
                    Node Checkpoint
                </StyledLink>
                <StyledLink to="/mystakes" isActive={pathname === '/mystakes'}>
                    My Stakes
                </StyledLink>
                <StyledLink
                    // to="/myalerts"
                    // isActive={pathname === '/myalerts'}
                    noHover
                    style={{ color: 'grey' }}
                    data-tooltip-id="my-tooltip-alert"
                >
                    My Alerts
                </StyledLink>
                <CustomTooltip
                    id="my-tooltip-alert"
                    place="bottom"
                    content="Soon"
                />
                {/* to="/myalerts" isActive={pathname === '/myalerts'} */}
                <StyledLink
                    noHover
                    // style={{ color: 'grey' }}
                    to="/faq"
                    isActive={pathname === '/faq'}
                >
                    F.A.Q.
                </StyledLink>
            </Navigation>
            <Toggle isActive={id === 'dark'} onToggle={setTheme} />
            <GateOmegaInfo />
            <StyledLink
                to="/privacypolicy"
                isActive={pathname === '/privacypolicy'}
                style={{ fontSize: '9px' }}
            >
                Privacy Policy and Term of Use
            </StyledLink>
        </SideNavbar>
    );
}
