import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Border } from '../../styles/border';
import SingleApyChart from '../charts/SingleApyChart';

const ValidatorLeft = styled(Border)`
    flex: 1 1 0%;
    gap: 4px;
    padding: 18px 25px;
    -webkit-box-pack: center;
    justify-content: center;

    h1 {
        min-width: 0px;
        font-weight: 600;
        font-size: 1rem;
        color: #54c9a8;
    }

    p {
        margin: 4px 0px -6px;
        font-weight: 400;
        font-size: 1rem;
    }
`;
const ValidatorApy = ({ validatorName, validatorData }) => {
    const [latestApy, setLatestApy] = useState(null);

    // console.log(latestApy);
    useEffect(() => {
        if (validatorData) {
            let latestApy = parseFloat(
                validatorData[validatorData.length - 1]?.apy
            );
            // console.log('latestApy:', latestApy);
            setLatestApy(latestApy);
        }
    }, [validatorData]);

    return (
        <>
            <ValidatorLeft>
                <h1>APY</h1>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <span style={{ fontSize: '1.2rem' }}>
                        {latestApy ? `${latestApy.toFixed(2)} %` : 'Loading...'}
                    </span>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ paddingRight: '5px', fontSize: '12px' }}>
                            30d
                        </span>
                        <SingleApyChart validatorData={validatorData} />
                    </div>
                </div>
            </ValidatorLeft>
        </>
    );
};

export default ValidatorApy;
