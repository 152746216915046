import React from 'react';
import { PageLayout } from '../components/layout/PageLayout';
import styled from 'styled-components';

const Wrapper = styled.div`
    gap: 10px;
    position: relative;

    @media (min-width: 80rem) {
        flex-direction: row;
    }
`;

const PolicyWrapper = styled(Wrapper)`
    padding: 16px 32px;
    border: 0.5px solid ${(p) => p.theme.widgetBorderColor};
    font-size: 0.875rem;

    & :link {
        text-decoration: none;
    }

    & :visited {
        text-decoration: none;
    }
`;

const StyledLink = styled.a`
    text-decoration: none;
    color: inherit;

    &:hover,
    &:active,
    &:visited {
        color: inherit;
    }
`;

function PrivacyPolicy() {
    return (
        <PageLayout>
            <PolicyWrapper>
                <div className="privacy-policy">
                    <h1>Privacy Policy & Terms of Use</h1>
                    <p>
                        <strong>Effective Date:</strong> 08 / 30 / 2023
                    </p>
                    <p>
                        GateOmega (herein referred to as "us", "we", or "our")
                        proudly manages the Suigo.app web application (the
                        "Service").
                    </p>
                    <p>
                        This document outlines our principles concerning the
                        collection, utilization, and disclosure of personal data
                        when you engage with our Service and the choices
                        available to you concerning that data.
                    </p>
                    <h2>Privacy Policy</h2>
                    <p>
                        By accessing and utilizing this website, as well as
                        setting up alerts, you expressly consent to the terms of
                        this privacy policy.
                    </p>
                    <p>
                        We are devoted to serving you better, and to this end,
                        we utilize your data to enhance and maintain the
                        Service. Your engagement with the Service implies your
                        consent to the gathering and use of your information in
                        sync with this policy. Unless explicitly defined herein,
                        terminologies in this Privacy Policy bear the same
                        connotations as in our Terms and Conditions.
                    </p>
                    <h3> Information Collection and Use</h3>
                    <p>
                        Our aim to provide you with an impeccable Service
                        necessitates the collection of varied information types.
                    </p>
                    <h4>Types of Data Collected</h4>
                    <ul>
                        <li>
                            Personal Data:
                            <p>
                                Personal Data During your interaction with our
                                Service, we may solicit specific personally
                                identifiable details to manage your account and
                                present exclusive content. This information
                                predominantly includes your Email and IP
                                Address.
                            </p>
                        </li>
                        <li>
                            Usage Data:
                            <p>
                                Further, we compile data about how the Service
                                is approached and utilized. This encompasses
                                details like the Service pages you peruse, your
                                visit timings, duration on those pages,
                                distinctive device identifiers, and other
                                diagnostic data.
                            </p>
                        </li>
                    </ul>
                    <h3> Use of Data</h3>
                    We employ the accumulated data for diverse objectives:
                    <ul>
                        <li>
                            Keeping you informed about modifications to our
                            Service.
                        </li>
                        <li>
                            Furnishing insights or valuable data, facilitating
                            Service enhancement.
                        </li>
                        <li>Observing the Service's consumption.</li>
                    </ul>
                    <h3> Analytics</h3>
                    To meticulously track and analyze our Service's usage, we
                    may collaborate with third-party Service Providers.
                    <ul>
                        <li>
                            Firebase Analytics: Operated by Google Inc.,
                            Firebase Analytics offers us analytical insights. If
                            you wish to opt out of certain Firebase features,
                            adjust your mobile device's settings, such as device
                            advertising preferences, or follow the guidelines
                            set out in Google's Privacy Policy. We also
                            recommend perusing Google's safeguarding policy. For
                            an in-depth understanding of the data Firebase
                            Analytics collects, please visit the Google Privacy
                            & Terms web page.
                        </li>
                    </ul>
                    <h3> Modifications to This Privacy Policy</h3>
                    <ul>
                        <li>
                            {' '}
                            We are committed to staying current, and as such,
                            may refine our Privacy Policy occasionally. Any
                            alterations will be declared on this page.
                        </li>
                        <li>
                            Before any such change takes effect, we will alert
                            you either via email or with a conspicuous
                            notification on our Service and update the "Last
                            Updated" date atop this Privacy Policy.
                        </li>
                        <li>
                            {' '}
                            We advise revisiting this Privacy Policy
                            occasionally to stay informed about any
                            modifications. Amendments are binding from the
                            moment they appear on this page.
                        </li>
                    </ul>
                    <h2> Terms of Use</h2>
                    <p>
                        Your use of this website signifies your acceptance of
                        the following terms. While we strive to maintain the
                        accuracy of the data presented on this site, we offer no
                        guarantees and endeavor to keep the information current
                        on a best-effort basis.
                    </p>{' '}
                    <p>
                        {' '}
                        The delivery of an alert is not guaranteed, and you
                        acknowledge that you won't rely solely on such alerts
                        for decision-making or other essential processes.
                        Despite our commitment to precision and consistent
                        service, the dynamic nature of our operational
                        environment means occasional data inconsistencies or
                        unavailability may occur.
                    </p>{' '}
                    <p>
                        By using this website, you relinquish any claims of
                        damages or losses you perceive might arise from its use.
                        Further, you agree to hold GateOmega harmless against
                        all claims from you, your colleagues, employer,
                        associates, or any third parties.
                    </p>
                    <h3>Contact Us</h3>
                    <p>For any inquiries or concerns:</p>
                    <ul>
                        <li>
                            Email us at:{' '}
                            <StyledLink href="mailto:dev@gateomega.com">
                                dev@gateomega.com
                            </StyledLink>
                        </li>
                        <li>
                            Or visit:{' '}
                            <StyledLink
                                href="https://gateomega.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                https://gateomega.com
                            </StyledLink>
                        </li>
                    </ul>
                    <p>
                        <em>
                            {' '}
                            By accessing our Service, you affirm your
                            comprehension of this Privacy Policy & Terms of Use
                            and consent to its terms and stipulations.
                        </em>
                    </p>
                </div>
            </PolicyWrapper>
        </PageLayout>
    );
}

export default PrivacyPolicy;
