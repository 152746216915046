import React from 'react';
import { BsSun } from 'react-icons/bs';
import { BsMoonFill } from 'react-icons/bs';
import styled from 'styled-components';

const ToggleWrapper = styled.div`
    width: 36px;
    min-width: 36px;
    height: 20px;
    background-color: ${(p) => p.theme.toggleColor};
    border-radius: 16px;
    display: flex;
    border: 1px solid #666;
`;

const Notch = styled.div`
    height: 16px;
    width: 16px;
    background: ${(p) => (p.$isActive ? '#01c2d5' : 'white')};
    border-radius: 50%;
    margin-top: 1px;
    border: 1px solid #666;
    transition: transform 0.1s linear;
    transform: translate(${(p) => (p.$isActive ? '16px' : '1px')});
`;

const BottomSide = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export function MobileToggle({ isActive, onToggle }) {
    return (
        <BottomSide>
            <BsSun />
            <ToggleWrapper onClick={onToggle}>
                <Notch $isActive={isActive} />
            </ToggleWrapper>
            <BsMoonFill />
        </BottomSide>
    );
}
